import React from 'react'
import { ColumnT, SortT } from '@types'
import { FiArrowDown, FiArrowUp } from 'react-icons/fi'

type SortProps = {
  sortBy: SortT
}
export const Sort = ({ sortBy }: SortProps) => {
  const className =
    'transition delay-150 duration-300 ease-in-out inline ml-2 absolute right-0 top-[20px]'
  return (
    <>
      {sortBy === 'desc' ? (
        <FiArrowDown className={className} />
      ) : (
        <FiArrowUp className={className} />
      )}
    </>
  )
}

type Props = {
  columns: ColumnT[]
  data: any[]
  sortBy?: SortT
  onSortChanged?: (key: SortT) => void
}
export const Table = ({
  columns,
  data,
  sortBy = 'desc',
  onSortChanged,
}: Props) => {
  return (
    <div>
      <table className="w-full text-sm font-normal border-collapse">
        <thead className="w-full font-medium bg-background-default">
          <tr className="tr-header">
            {columns.map((col, colIndex) => {
              return col.sort ? (
                <td
                  key={`col_${colIndex}`}
                  className={`tbl ${col.width} cursor-pointer relative`}
                  onClick={() =>
                    onSortChanged?.(sortBy === 'desc' ? 'asc' : 'desc')
                  }
                >
                  {col.label}
                  <Sort sortBy={sortBy} />
                </td>
              ) : (
                <td key={`col_${colIndex}`} className={`tbl ${col.width}`}>
                  {col.label}
                </td>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => {
            return (
              <tr className="tr-body" key={`row_${rowIndex}`}>
                {columns.map((col, colIndex) => {
                  return (
                    <td
                      key={`${rowIndex}_${colIndex}`}
                      className={`tbl ${col.width}`}
                    >
                      {col.render?.(row, rowIndex + 1) ?? row[col.key] ?? '-'}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {!data.length && <EmptyData />}
    </div>
  )
}

export const EmptyData = () => {
  return (
    <div className="tbl w-1/1 bg-red text-center text-text-subdued text-sm">
      No Data
    </div>
  )
}
