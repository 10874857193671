import React from 'react'

import { LEAVE_STATUS_OPTIONS, LEAVE_TYPE_OPTIONS } from '@lib/constants'
import { formatDateTime, FULL_DATE_PARAM } from '@lib/helper'
import { LeaveT, LeaveParam } from '@types'

import { Calendar, Select } from '@components'

type Props = {
  onChange: (filter?: LeaveParam) => void
  data: LeaveT[]
  searchFilter?: LeaveParam
}

export const SearchFilter = ({ onChange, data, searchFilter }: Props) => {
  return (
    <div className="grid w-full sm:grid-cols-1 md:grid-cols-[220px_220px_220px_auto] gap-2 my-4 md:items-center sm:items-baseline">
      <Select
        onChange={(value) =>
          onChange({ ...searchFilter, leave_type: value?.value as string })
        }
        options={LEAVE_TYPE_OPTIONS}
        value={searchFilter?.leave_type}
      />
      <Calendar
        placeholder="All Date Time Range"
        onChange={(value) =>
          onChange({
            ...searchFilter,
            starts_date: formatDateTime(
              value?.[0] as unknown as string,
              FULL_DATE_PARAM,
            ),
            ends_date: formatDateTime(
              value?.[1] as unknown as string,
              FULL_DATE_PARAM,
            ),
          })
        }
      />
      <Select
        onChange={(value) =>
          onChange({ ...searchFilter, status: value?.value as string })
        }
        options={LEAVE_STATUS_OPTIONS}
        value={searchFilter?.status}
      />
      <div className="font-bold text-sm">
        Matching: <span className="font-normal">{data.length} items</span>
      </div>
    </div>
  )
}
