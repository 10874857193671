import React from 'react'
import { Calendar, Select, Input } from '@components'
import { SEARCH_EMPLOYEE_ROLE_OPTIONS } from '@lib/constants'
import { formatDateTime, FULL_DATE_PARAM } from '@lib/helper'
import { ActivityLogsDataT, ActivityLogsParam } from '@types'

type Props = {
  onChange: (filter?: ActivityLogsParam) => void
  searchFilter?: ActivityLogsParam
}
export const SearchFilter = ({ onChange, searchFilter }: Props) => {
  return (
    <div className="grid w-full sm:grid-cols-1 md:grid-cols-[220px_220px_220px_auto] gap-2 my-4 md:items-center sm:items-baseline">
      <Input
        name="query"
        value={searchFilter?.query}
        placeholder={'Search'}
        onChange={(value) =>
          onChange({ ...searchFilter, query: value as string})
        }
      />
      <Calendar
        placeholder="All Date Time Range"
        onChange={(value) =>
          onChange({
            ...searchFilter,
            starts_date: formatDateTime(
              value?.[0] as unknown as string,
              FULL_DATE_PARAM,
            ),
            ends_date: formatDateTime(
              value?.[1] as unknown as string,
              FULL_DATE_PARAM,
            ),
          })
        }
      />
      <Select
        onChange={(value) =>
          onChange({ ...searchFilter, role: value?.value as string })
        }
        options={SEARCH_EMPLOYEE_ROLE_OPTIONS}
        value={searchFilter?.role}
      />
    </div>
  )
}
