import { AxiosError } from 'axios'
import { concat } from 'lodash'

import { axios, getErrorMessage } from '@lib/helper'
import {
  adminActivityLogsApi
} from '@path'
import { ActivityLogsResponse } from '@types'

export const activityLogServices = () => {
  return {
    getActivityLogs: async (payload?: {}): Promise<ActivityLogsResponse> => {
      const res = await axios.get(adminActivityLogsApi, {params: payload})
      return res.data || {}
    },
  }
}
