import React, { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { withApp } from '@features/layout'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { EventInput } from '@fullcalendar/core'
import { concat } from 'lodash'
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from '@material-tailwind/react'

import { useAuth } from '@features/layout/useAuth'
import { FULL_DATE_PARAM, renderLeaveName } from '@lib/helper'
import { CalendarData, CalendarParams, EmployeeT, HolidayData } from '@types'

import { SearchFilter } from '../components/SearchFilter'
import { CalendarServices } from '../services'
import { useMediaQuery } from 'react-responsive'
import { HolidayServices } from '@lib/services'

dayjs.extend(advancedFormat)

const Home = () => {
  const { me } = useAuth()
  const { theme } = require('@config/tailwind.config')
  const { screens } = theme.extend
  const isMobile = useMediaQuery({
    query: `(max-width: ${screens.md})`,
  })
  const { getCalendar } = CalendarServices()
  const { getHoliday } = HolidayServices()
  const [search, setSearch] = useState<CalendarParams>()
  const [calendarData, setCalendarData] = useState<CalendarData[]>()
  const [holidayData, setHolidayData] = useState<HolidayData[]>()

  const ACCEPT_TYPES = [
    'working_from_office',
    'working_from_home',
    'working_remotely',
  ]

  const events = useMemo(() => {
    if (calendarData) {
      let formatData: any[] = []

      calendarData?.map((item) => {
        let data = item.leaves.map((leave) => {
          if (leave) {
            return {
              title: leave.type,
              start: item.date,
              extendedProps: {
                count: leave.count,
                data: leave.periods,
                hovered: ACCEPT_TYPES.includes(leave.type)
                  ? 'hover:bg-surface-highlight-subdued-pressed'
                  : 'hover:bg-surface-critical-subdued-hovered',
              },
              backgroundColor: ACCEPT_TYPES.includes(leave.type)
                ? 'bg-surface-highlight-subdued-hovered'
                : 'bg-surface-critical-subdued-default',
              borderColor: ACCEPT_TYPES.includes(leave.type)
                ? 'border-text-highlight'
                : 'border-text-critical',
            }
          }
        })
        formatData = concat(formatData, data)
      })

      const holiday = holidayData?.map((item) => {
        return {
          title: item.title,
          start: item.date,
          extendedProps: {
            isHoliday: true,
            color: 'text-text-warning',
            hovered: 'hover:bg-surface-warning-subdued-hovered',
          },
          backgroundColor: 'bg-surface-warning-subdued-pressed',
        }
      })
      formatData = concat(formatData, holiday)

      return formatData
    }
    return []
  }, [calendarData])

  const getLeavesData = async () => {
    if (search?.starts_date) {
      const data = await getCalendar(search)
      setCalendarData((data?.calendar_data as unknown as CalendarData[]) ?? [])
    }
  }

  const getHolidaysData = async () => {
    const data = await getHoliday()
    setHolidayData((data?.holiday_data as unknown as HolidayData[]) ?? [])
  }

  useEffect(() => {
    getLeavesData()
  }, [search])

  useEffect(() => {
    getHolidaysData()
  }, [])

  const renderEventContent = (eventInfo: EventInput) => {
    const { title, extendedProps, backgroundColor, borderColor } =
      eventInfo.event
    const { data, isHoliday, color } = extendedProps

    const renderContent = (
      title: string,
      count: number,
      employee: EmployeeT[],
    ) => {
      return (
        <div className="border-b-[1px] border-border-default-subdued p-[8px_0] text-xs font-normal">
          <p className="pb-1">
            {title} {`(${count})`}
          </p>
          {employee.map((item: { employee_name: string }, index) => (
            <p className="text-text-subdued" key={index}>
              {item.employee_name}
            </p>
          ))}
        </div>
      )
    }
    return (
      <div className="w-full">
        {isHoliday ? (
          <div
            className={`${backgroundColor} ${extendedProps.hovered} ${color} focus-visible:outline-0 w-full border-l-2  p-[4px] rounded-tr-4 rounded-br-4 text-xs`}
          >
            <span className={color}>{title}</span>
          </div>
        ) : (
          <Popover
            placement="right-end"
            dismiss={{
              ancestorScroll: true,
            }}
          >
            <PopoverHandler>
              <div
                className={`grid grid-cols-[25px_auto] ${backgroundColor} ${extendedProps.hovered} focus-visible:outline-0 w-full border-l-2 ${borderColor} p-[4px] rounded-tr-4 rounded-br-4 cursor-pointer text-xs overflow-hidden`}
                tabIndex={0}
              >
                <p>{extendedProps?.count}</p>
                <p>{renderLeaveName(title)}</p>
              </div>
            </PopoverHandler>
            <PopoverContent
              className={'focus-visible:outline-none min-w-[156px]'}
            >
              <p className="font-medium text-xs">
                {renderLeaveName(title)} ({extendedProps?.count})
              </p>
              {!!data?.all_day.count &&
                renderContent(
                  'All Day',
                  data.all_day.count,
                  data.all_day.employees,
                )}
              {!!data?.first_half.count &&
                renderContent(
                  'First Half',
                  data.first_half.count,
                  data.first_half.employees,
                )}
              {!!data?.second_half.count &&
                renderContent(
                  'Second Half',
                  data.second_half.count,
                  data.second_half.employees,
                )}
            </PopoverContent>
          </Popover>
        )}
      </div>
    )
  }

  return (
    <div>
      <div className="mb-[24px]">
        <div className="font-bold flex sm:flex-col md:flex-row">
          <h1>Welcome </h1>
          {me?.name && <h1 className="md:pl-2">{me?.name}!</h1>}
        </div>
        <p>{`Today - (${dayjs().format('ddd')}) ${dayjs().format(
          'Do MMM YYYY',
        )}`}</p>
      </div>
      {isMobile ? (
        <div className="flex mb-4">
          <SearchFilter onChange={setSearch} search={search} />
        </div>
      ) : (
        <div className="absolute right-[20px]">
          <SearchFilter onChange={setSearch} search={search} />
        </div>
      )}

      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventContent={renderEventContent}
        datesSet={(e) =>
          setSearch({
            ...search,
            starts_date: dayjs(e.view.currentStart).format(FULL_DATE_PARAM),
          })
        }
      />
    </div>
  )
}
export default withApp(Home)
