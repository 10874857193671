import { useEffect, useState } from 'react'
import { MeResponsesT } from '@types'
import { authServices } from './services'

export const useAuth = () => {
  const { getMe } = authServices()
  const [me, setMe] = useState<MeResponsesT>()
  const getUserInformations = async () => {
    const response = await getMe()
    setMe(response)
  }

  useEffect(() => {
    getUserInformations()
  }, [])
  return {
    me,
  }
}
