export const reviewLeavesPath = (id: number) => `/leaves/${id}/review`

// menu path
export const leavesPath = '/leaves'
export const teamLeavesPath = '/team_leaves'
export const userManagementPath = '/admin/employees'
export const companySettingsPath = '/admin/company_settings'
export const approveLevelPath = '/admin/company_settings/approval_levels'
export const leaveTypesPath = '/admin/company_settings/leave_types'
export const leaveBalancesPath = '/admin/company_settings/leave_balances'
export const calendarPath = '/calendar'
export const holidayPath = '/holiday'
export const activityLogsPath = '/admin/activity_logs'
// api
export const mePath = '/employees/me'
export const logoutPath = '/employees/sign_out'
export const leavesPathApi = '/leaves'

export const teamLeavePathApi = '/team_leaves'
export const teamLeavePendingPathApi = '/team_leaves/pending_leave'
export const teamLeaveApprovalPathApi = '/team_leaves/approval_leave'

export const teamsPathApi = '/teams'
export const adminEmployeesPathApi = '/admin/employees'
export const adminManagerPathApi = '/admin/managers'

export const adminLeaveBalancesApi = '/admin/company_settings/leave_balances'
export const adminLeaveBalancesEmployeeQuotaApi = '/admin/company_settings/leave_balances/employee_quota'
export const adminLeaveBalancesAdjustQuotaApi = '/admin/company_settings/leave_balances/adjust_quota'
export const adminLeaveBalancesBulkUpdateQuotaApi = '/admin/company_settings/leave_balances/bulk_update_quota'

export const adminActivityLogsApi = '/admin/activity_logs'
