import React, { useState } from 'react'
import { FaPen } from 'react-icons/fa'
import { IoMdTrash } from 'react-icons/io'

import {
  formatDateTime,
  formatDateWithPeriod,
  formatDisplayDate,
  renderLeaveName,
} from '@lib/helper'
import { ColumnT, LeaveT, SortT } from '@types'

import { LEAVE_DELETE_MODAL } from './LeaveDeleteModal'
import { LEAVE_EDIT_MODAL } from '../pages/LeavesPage'
import { Status, Table } from '@components'

type Props = {
  data: LeaveT[]
  onSortChange: (data: string) => void
  sort?: string
  onSelectData: (data: LeaveT, modal?: string) => void
}

export const LeaveTable = ({
  data,
  onSortChange,
  sort = 'desc',
  onSelectData,
}: Props) => {
  const [sortBy, setSortBy] = useState<SortT>(sort as SortT)

  const onChange = (value: SortT) => {
    setSortBy(value)
    onSortChange(value)
  }

  const columns: ColumnT[] = [
    {
      label: 'Submitted Date',
      key: 'created_at',
      sort: true,
      width: 'w-[15%]',
      render: (row) => <> {formatDateTime(row.created_at)}</>,
    },
    {
      label: 'Leave Type',
      key: 'leave_type_name',
      width: 'sm:min-w-[120px] md:w-[15%]',
      render: (row) => <>{renderLeaveName(row.leave_type_name)}</>,
    },
    {
      label: 'Leave Date',
      key: 'datetime_range',
      width: 'sm:min-w-[120px] md:w-[20%] break-words',
      render: (row) => (
        <>
          {formatDateWithPeriod(
            formatDisplayDate(
              row.starts_date,
              row.ends_date,
              row.datetime_range,
            ),
            row.period,
          )}
        </>
      ),
    },
    {
      label: 'Details',
      key: 'reason',
      width: 'sm:min-w-[120px] md:w-[15%]',
      render: (row) => (
        <div className="text-wrap sm:max-w-[120px] lg:max-w-[180px] 2xl:max-w-[320px]">
          {row.reason ?? '-'}
        </div>
      ),
    },
    {
      label: 'Status',
      key: 'status',
      width: 'w-[10%]',
      render: (row) => <Status status={row.status} type="leave" />,
    },
    {
      label: 'Approved by',
      key: 'reviewed_by_name',
      width: 'w-[20%]',
    },
    {
      label: '',
      key: 'id',
      width: 'w-[5%]',
      render: (row) => (
        <div className="flex gap-2 justify-end">
          {row.status !== 'approved' && (
            <label htmlFor={LEAVE_EDIT_MODAL}>
              <FaPen
                className="text-icon-disabled hover:text-icon-subdued cursor-pointer text-[14px] mt-[2px]"
                onClick={() => onSelectData(row, LEAVE_EDIT_MODAL)}
              />
            </label>
          )}
          <label htmlFor={LEAVE_DELETE_MODAL}>
            <IoMdTrash
              className="text-icon-disabled hover:text-icon-subdued cursor-pointer text-[18px]"
              onClick={() => onSelectData(row)}
            />
          </label>
        </div>
      ),
    },
  ]
  return (
    <div className="overflow-x-auto">
      <Table
        columns={columns}
        data={data}
        sortBy={sortBy}
        onSortChanged={onChange}
      />
    </div>
  )
}
