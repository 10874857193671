import React from 'react'
import { RiErrorWarningFill } from 'react-icons/ri'
type Props = {
  text: string
  bg?: string
}
export const ErrorText = ({ text, bg }: Props) => {
  return (
    <div
      className={`flex gap-[10px] p-[4px_0px] ${bg && `bg-${bg}`} items-center`}
    >
      <RiErrorWarningFill className="text-text-critical" />
      <p className="text-sm text-text-critical">{text}</p>
    </div>
  )
}
