import React from 'react'

import { closeModal, Modal } from '@components'
import { LeaveT } from '@types'
import { capitalize } from 'lodash'
import { formatDisplayDate, formatPeriod } from '@lib/helper/format'

export const LEAVE_DELETE_MODAL = 'leave_delete_modal'

type Props = {
  onSubmit: (id: number) => void
  data: LeaveT
}
export const LeaveDeleteModal = ({ onSubmit, data }: Props) => {
  const boxData = [
    {
      label: 'Leave type',
      value: capitalize(data.leave_type_name.split('_').join(' ')),
      style: 'capitalize',
    },
    {
      label: 'Date',
      value: formatDisplayDate(
        data.starts_date,
        data.ends_date,
        data.datetime_range,
      ),
    },
    {
      label: 'Period',
      value: formatPeriod(data.period),
      style: 'capitalize',
    },
    {
      label: 'Reason',
      value: data.reason ?? '-',
      style: 'word-break',
    },
  ]
  return (
    <Modal name={LEAVE_DELETE_MODAL} closeIcon={false} width={500}>
      <div className="grid text-center justify-center">
        <div className="grid gap-5 mb-5 ">
          <img src="/assets/warning_icon.svg" className="m-auto" />
          <h2 className="font-bold">Delete Leave</h2>
        </div>

        <div className="grid gap-4">
          <p className="text-text-subdued">
            This leave requested below will be deleted.
          </p>
          <div className="card card-border">
            {boxData.map((item) => (
              <div className="grid grid-cols-[1fr_2fr] text-left text-sm">
                <p className="font-semibold">{item.label}:</p>
                <p className={item.style}>{item.value}</p>
              </div>
            ))}
          </div>
          <p className="text-text-subdued">Do you want to delete anyway?</p>
        </div>
        <div className="flex flex-row gap-4 justify-center pt-6">
          <button
            className="btn btn-secondary-solid"
            onClick={() => closeModal([LEAVE_DELETE_MODAL])}
          >
            Cancel
          </button>
          <button
            className="btn btn-destructive-solid"
            onClick={() => onSubmit(data.id)}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  )
}
