import React, { useEffect, useState } from 'react'
import { concat } from 'lodash'

import { LEAVE_TYPE_OPTIONS } from '@lib/constants'
import { CalendarParams, OptionT } from '@types'

import { Select } from '@components'
import { CalendarServices } from '../services'

type Props = {
  search?: CalendarParams
  onChange?: (value: any) => void
}
export const SearchFilter = ({ search, onChange }: Props) => {
  const { getTeams } = CalendarServices()
  const [teamOptions, setTeamOptions] = useState<OptionT[]>([])

  const getTeamsOptions = async () => {
    const res = await getTeams()
    let format: OptionT[] = res?.departments.map((item) => {
      return { label: item.name, value: item.id }
    })

    format = concat(
      [
        {
          label: 'All Teams',
          value: undefined,
        },
      ],
      format,
    )

    setTeamOptions(format)
  }

  useEffect(() => {
    getTeamsOptions()
  }, [])

  return (
    <div className="grid md:grid-cols-[200px_200px] gap-[8px] w-full">
      <Select
        options={teamOptions}
        value={search?.department_id}
        onChange={(data) =>
          onChange?.({ ...search, department_id: data?.value })
        }
      />
      <Select
        options={LEAVE_TYPE_OPTIONS}
        value={search?.leave_type}
        onChange={(data) => onChange?.({ ...search, leave_type: data?.value })}
      />
    </div>
  )
}
