import React, { useState } from 'react'
import { Modal } from '@components'

type Props = {
  handleUpload: (file: File) => void
  modalName: string
  onClose: (name: string) => void
}

export const ImportFormModal = ({
  handleUpload,
  modalName,
  onClose
}: Props) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const uploadQuota = () => {
    if (file) {
      const formData = new FormData();
      formData.append("excel_file", file);

      handleUpload(formData);
    }
  };

  return (
    <Modal
      name={modalName}
      title={'Adjust Quota'}
      onClose={() => onClose(modalName)}
    >
      <div>
        <label htmlFor="file" className="sr-only">
          Choose a file
        </label>
        <input id="file" type="file" onChange={handleFileChange} />
      </div>
      <br></br>
      {file && (
        <section>
          File details:
          <ul>
            <li>Name: {file.name}</li>
            <li>Type: {file.type}</li>
            <li>Size: {file.size} bytes</li>
          </ul>
        </section>
      )}

      <div className="w-full flex flex-row justify-end space-x-4 mt-[32px]">
        <button
          type="button"
          className="btn btn-primary-outline capitalize"
          onClick={() => onClose(modalName)}
        >
          Cancel
        </button>
        <button className="btn btn-primary-solid" onClick={uploadQuota}>
          Upload a file
        </button>
      </div>
    </Modal>
  );
}
