import React, { useMemo } from 'react'
import { OptionT } from '@types'

type Props = {
  option: OptionT
  value?: string
  onChange?: (value: string | number) => void
}
export const Radio = ({ option, value, onChange }: Props) => {
  const isChecked = useMemo(() => value === option.value, [value])
  return (
    <div
      className={`flex flex-row gap-2 p-[12px] rounded border-[1px] ${
        isChecked
          ? 'border-border-default-primary'
          : 'border-border-default-subdued'
      } w-full`}
    >
      <input
        type="radio"
        className={`radio radio-sm border-2 bg-white text-text-primary-default border-border-default-default checked:bg-interactive-default`}
        checked={isChecked}
        onChange={() => onChange?.(option.value)}
      />
      <p className="text-sm">{option.label}</p>
    </div>
  )
}
