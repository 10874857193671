import React from 'react'
import { withApp } from '@features/layout'
import { approveLevelPath, leaveTypesPath, leaveBalancesPath } from '@path'

const CompanySettings = () => {
  const settings = [
    {
      label: 'Leave Balances',
      description: 'description',
      img: 'vacation_leave',
      path: leaveBalancesPath,
    },
  ]
  return (
    <div className="w-full bg-white flex flex-col">
      <h1 className="font-bold">Company Settings</h1>
      <div
        className="card grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4
        justify-items-center rounded-2xl bg-background-default p-4 gap-4 mt-[24px] mb-4 cursor-pointer"
      >
        {settings.map((item, index) => (
          <a
            href={item.path}
            className="grid grid-cols-[1fr_2fr] w-full h-[108px] bg-white rounded-xl shadow-[0px_0px_5px_rgba(0,0,0,0.05),0px_1px_2px_rgba(0,0,0,0.15)]"
            key={index}
          >
            <div className="m-auto">
              <img src={`/assets/${item.img}.svg`} />
            </div>
            <div className="my-auto">
              <p className="font-bold">{item.label}</p>
              <p className="text-sm">{item.label}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}

export default withApp(CompanySettings)
