import React, { ComponentType } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'

import { SideBar } from './component/Sidebar'
import { useAuth } from './useAuth'

type Props = {}

export const MainLayout = ({ children }: React.PropsWithChildren<Props>) => {
  const { me } = useAuth()
  if (!me) {
    return <></>
  }
  return (
    <div className="drawer">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="flex drawer-content h-screen">
        <div className="w-[250px] border-r-[2px] border-border-default-subdued sm:hidden md:flex md:fixed">
          <SideBar data={me} />
        </div>
        <div className="w-full">
          <div className="navbar sm:flex md:hidden gap-[24px] sm:justify-between md:justify-end w-[calc(100%)] h-[50px] px-[24px]">
            <label htmlFor="my-drawer">
              <GiHamburgerMenu className="sm:block md:hidden text-[20px]" />
            </label>
            {/* <div className="flex gap-4">
              <div className="grid text-end">
                <div className="font-semibold">{me.name}</div>
                <div className="text-sm text-text-subdued">{me.role}</div>
              </div>
              <div className="avatar">
                <div className="w-[44px] rounded-full">
                  <img src={me.avatar_url} />
                </div>
              </div>
            </div> */}
          </div>
          <div className="sm:w-full h-[100vh] md:w-[calc(100%-250px)] overflow-auto sm:py-[10px] md:py-[24px] px-[24px] md:absolute left-[250px]">
            {children}
          </div>
        </div>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer" className="drawer-overlay" />
        <ul className="menu w-[250px] bg-base-100 text-base-content">
          <SideBar data={me} />
        </ul>
      </div>
    </div>
  )
}

export const withApp =
  (Component: ComponentType<any>) => (props: JSX.IntrinsicAttributes) => {
    return (
      <MainLayout>
        <Component {...props} />
      </MainLayout>
    )
  }
