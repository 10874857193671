import React from 'react'
import { Toaster as ReactToaster } from 'react-hot-toast'

const { theme } = require('@config/tailwind.config')

const Toaster = () => {
  const { colors } = theme.extend
  return (
    <ReactToaster
      position="top-right"
      reverseOrder={false}
      toastOptions={{
        success: {
          style: {
            maxWidth: '620px',
            backgroundColor: colors.surface.success.subdued,
            border: `1px solid ${colors.border.success.subdued}`,
            padding: '16px',
          },
        },
        error: {
          style: {
            maxWidth: '620px',
            backgroundColor: colors.surface.critical.subdued,
            border: `1px solid ${colors.border.critical.subdued}`,
            padding: '16px',
          },
        },
      }}
    />
  )
}

export default Toaster
