import React, { useState } from 'react'
import { Input } from '@components'

type Props = {
  value?: {leave_type_name:string, quota: number}
  updateFormValues: (name:string, value: number) => void
}

export const QuotaInput = ({ value, updateFormValues }: Props) => {
  const [quota, setQuota] = useState<number>(value?.quota || 0)

  const setQuotaAndUpdateData = (val:number) => {
    setQuota(val);
    updateFormValues(value?.leave_type_name || '', val)
  }

  return (
    <div className="grid grid-cols-2 gap-4 w-full">
      <Input
        placeholder="0.0"
        step={0.5}
        name="quota"
        type="number"
        value={quota.toString()}
        onChange={(val) => setQuotaAndUpdateData(parseFloat(val))}
        className={'p-2'}
      />
      {quota != value?.quota && <div className='p-2 font-semibold text-text-primary-default'>Adjust!</div>}
    </div>
  )
}

