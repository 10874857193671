import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { z } from 'zod'

import { CreateTeamParam } from '@types'
import { Input, Modal } from '@components'

type Props = {
  onSubmit: (payload: CreateTeamParam) => void
  modalName: string
  onClose: (name: string) => void
  value?: CreateTeamParam
}

export const TeamFormModal = ({
  modalName,
  onClose,
  value,
  onSubmit,
}: Props) => {
  const schema = z.object({
    name: z
      .string({
        required_error: 'name is required',
      })
      .min(1, {
        message: 'name is required',
      }),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateTeamParam>({
    resolver: zodResolver(schema),
    defaultValues: value,
  })

  return (
    <Modal
      name={modalName}
      title={`${value ? 'Edit Team' : 'Create Team'}`}
      onClose={() => onClose(modalName)}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full gap-4"
      >
        <Input
          name={'name'}
          register={register}
          label="Team Name"
          required
          errorMessage={errors.name?.message}
        />
        <div className="w-full flex flex-row justify-end space-x-4">
          <button
            type="button"
            className="btn btn-primary-outline capitalize"
            onClick={() => onClose(modalName)}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary-solid">
            {value ? 'Save Changes' : 'Create'}
          </button>
        </div>
      </form>
    </Modal>
  )
}
