const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  content: [
    './public/*.html',
    './app/helpers/**/*.rb',
    './app/javascript/**/*.js',
    './app/views/**/*.{erb,html}',
    './app/javascript/**/*.js',
    './app/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    extend: {
      fontFamily: {
        sans: ['IBM Plex Sans Thai'],
      },
      screens: {
        sm: '0px',
        md: '768px',
        lg: '960px',
        xl: '1200px',
        '2xl': '1536px',
      },
      borderRadius: {
        regular: '24px',
      },
      maxHight: {
        'max-h-52': 'max-height: 13rem;',
      },
      colors: {
        background: {
          default: '#F6F6F7',
          hovered: '#F1F2F3',
          pressed: '#EDEEEF',
          selected: '#EDEEEF',
        },
        decorative: {
          surface: {
            linearGradient: '#BCF1EE',
            1: '#FFC96B',
            2: '#FFC4B0',
            3: '#92E6B5',
            4: '#91E0D6',
            5: '#FDC9D0',
          },
          text: {
            1: '#3D2800',
            2: '#490B1C',
            3: '#002F19',
            4: '#002D2D',
            5: '#4F0E1F',
          },
          icon: {
            1: '#7E5700',
            2: '#AF294E',
            3: '#006D41',
            4: '#006A68',
            5: '#AE2B4C',
          },
          primary: {
            0: '#F5FFFE',
            100: '#C5FDFA',
            200: '#98F9F4',
            300: '#70F2EC',
            400: '#50E9E2',
            500: '#38DDD5',
            600: '#27CDC4',
            700: '#1BB8B0',
            800: '#13A099',
            900: '#0E8781',
          },
        },
        surface: {
          default: {
            default: '#FFFFFF',
            subdued: '#FAFBFB',
            hovered: '#F6F6F7',
            pressed: '#F1F2F3',
            depressed: '#EDEEEF',
            diabled: '#FAFBFB',
            searchField: '#F1F2F3',
          },
          primary: {
            default: '#E2F9F7',
            subdued: '#F0FCFB',
            hovered: '#BCF1ED',
            pressed: '#91E7E2',
          },
          critical: {
            default: '#FED3D1',
            subdued: {
              default: '#FFF4F4',
              hovered: '#FFF0F0',
              pressed: '#FFE9E8',
              depressed: '#FEBCB9',
            },
          },
          disabled: {
            default: '#D9D9D9',
            subdued: '#E8E8E8',
          },
          highlight: {
            default: '#A4E8F2',
            subdued: {
              default: '#EBF9FC',
              hovered: '#E4F7FA',
              pressed: '#D5F3F8',
            },
          },
          neutral: {
            default: '#E4E5E7',
            subdued: '#F6F6F7',
            hovered: '#DBDDDF',
            pressed: '#C9CCD0',
            disabled: '#F1F2F3',
          },
          selected: {
            default: '#F0FCFB',
            hovered: '#E2F9F7',
            pressed: '#BCF1ED',
          },
          success: {
            default: '#AEE9D1',
            subdued: {
              default: '#F1F8F5',
              hovered: '#ECF6F1',
              pressed: '#E2F1EA',
            },
          },
          warning: {
            default: '#FFD79D',
            subdued: {
              default: '#FFF5EA',
              hovered: '#FFF2E2',
              pressed: '#FFEBD3',
            },
          },
        },
        text: {
          default: '#2B2B2B',
          subdued: '#6D7175',
          disabled: '#8C9196',
          critical: '#D72C0D',
          warning: '#916A00',
          success: '#008060',
          highlight: '#347C84',
          on: {
            interactive: '#FFFFFF',
            primary: '#FFFFFF',
            critical: '#FFFFFF',
          },
          primary: {
            default: '#38ACA5',
            hovered: '#38ACA5',
            pressed: '#005C44',
          },
        },
        icon: {
          primary: '#38ACA5',
          default: '#5C5F62',
          subdued: '#8C9196',
          hovered: '#1A1C1D',
          pressed: '#44474A',
          disabled: '#BABEC3',
          critical: '#D72C0D',
          warning: '#B98900',
          success: '#007F5F',
          highlight: '#00A0AC',
          interactive: '#2C6ECB',
          on: {
            interactive: '#FFFFFF',
            primary: '#FFFFFF',
            critical: '#FFFFFF',
          },
        },
        border: {
          default: {
            primary: '#38ACA5',
            disabled: '#D2D5D8',
            default: '#8C9196',
            subdued: '#E8E8E8',
            hovered: '#999EA4',
            depressed: '#575959',
          },
          shadow: {
            borderShadow: '#AEB4B9',
            subdued: '#BABFC4',
          },
          neutral: {
            subdued: '#BABFC3',
          },
          highlight: {
            default: '#449DA7',
            subdued: '#98C6CD',
          },
          success: {
            default: '#00A47C',
            subdued: '#95C9B4',
          },
          warning: {
            default: '#B98900',
            subdued: '#E1B878',
          },
          critical: {
            default: '#FD5749',
            subdued: '#E0B3B2',
            disabled: '#FFA7A3',
          },
        },
        focused: {
          default: '#458FFF',
        },
        interactive: {
          default: '#38ACA5',
          hovered: '#2A807B',
          pressed: '#216763',
          disabled: '#BDC1CC',
          critical: {
            default: '#D82C0D',
            hovered: '#CD290C',
            disabled: '#FD938D',
            pressed: '#670F03',
          },
        },
        other: {
          divider: '#E1E3E5',
        },
        action: {
          primary: {
            default: '#38ACA5',
            hovered: '#2A807B',
            pressed: '#216763',
            depressed: '#194E4A',
            disabled: '#F1F1F1',
          },
          secondary: {
            default: '#DBDBDB',
            hovered: '#C7C7C7',
            pressed: '#858585',
            depressed: '#6D7175',
            disabled: '#D2D5D8',
          },
          critical: {
            default: '#D82C0D',
            hovered: '#BC2200',
            pressed: '#A21B00',
            depressed: '#6C0F00',
            disabled: '#F1F1F1',
          },
        },
        shadow: {
          dim: '#00000033',
          ambient: '#171818d',
          direct: '#00000026',
        },
        other: {
          backdrop: '#00000080',
          overlay: '#FFFFFF80',
          divider: '#E1E3E5',
          critical: {
            0: '#FFF5F5',
            100: '#FDD8D8',
            200: '#F9BBBB',
            300: '#F2A1A1',
            400: '#E98B8B',
            500: '#DD7878',
            600: '#CD6767',
            700: '#B85858',
            800: '#A04A4A',
            900: '#873D3D',
          },
        },
      },
    },
    fontWeight: {
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '16px',
      lg: '18px',
      xl: '20px',
      '2xl': '22px',
      '3xl': '24px',
      '4xl': '28px',
      '5xl': '32px',
      '6xl': '36px',
    },
  },
  daisyui: {
    themes: false,
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography'),
    require('daisyui'),
  ],
}
