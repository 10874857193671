import React from 'react'
import { toast, Toast as ToastInterface } from 'react-hot-toast'

const { theme } = require('@config/tailwind.config')

type ToastType = 'success' | 'error'

export type ShowToastProps = {
  type: ToastType
  message: string
}

export type ToastProps = ShowToastProps & {
  id: string
}

const getIconSrc = (type: ToastType) => {
  switch (type) {
    case 'success':
      return '/images/success-icon.svg'
    case 'error':
      return '/images/critical-icon.svg'
  }
}

export const Toast = ({ id, type, message }: ToastProps) => {
  return (
    <>
      <img
        src={getIconSrc(type)}
        onClick={() => hideToast(id)}
        className="absolute left-4 top-[calc(50% - 20px)] cursor-pointer"
      />
      <div
        className={`flex flex-row relative space-x-2 w-full h-full max-w-[540px] pl-1.5 mr-5 break-words`}
      >
        {message}
      </div>
      <img
        src="/images/close-toast.svg"
        onClick={() => hideToast(id)}
        className="absolute right-4 top-[calc(50% - 20px)] cursor-pointer"
      />
    </>
  )
}

export const showToast = (props: ShowToastProps) => {
  const toastComponent = (t: ToastInterface) => <Toast id={t.id} {...props} />
  const { colors } = theme.extend
  const disableDefaultIcon = {
    icon: '',
  }

  switch (props.type) {
    case 'success': {
      return toast.success(toastComponent, {
        ...disableDefaultIcon,
        style: {
          background: colors.surface.success.subdued.default,
        },
      })
    }
    case 'error': {
      return toast.error(toastComponent, {
        ...disableDefaultIcon,
        style: {
          background: colors.surface.critical.subdued.default,
        },
      })
    }
  }
}

export const hideToast = (id: string) => toast.dismiss(id)
