import React, { useEffect, useState } from 'react'
import { withApp } from '@features/layout'
import {
  closeModal,
  BreadCrumb,
  Pagination,
  Table,
  ExportIcon
} from '@components'
import { companySettingsPath, leaveBalancesPath } from '@path'
import { leaveBalanceServices } from '../services'
import {
  LeaveBalancesDataT,
  LeaveBalancesResponse,
  LeaveBalancesParam,
  ColumnT,
} from '@types'
import { BsPlus } from 'react-icons/bs'
import { FaPen } from 'react-icons/fa'
import { EmployeeLeaveTypesFormModal } from '../components/EmployeeLeaveTypesFormModal'
import { ImportFormModal } from '../components/ImportFormModal'
import { SearchEmployee } from '../components/SearchEmployee'

export const QUOTA_MODAL = 'employee-leave-types-form-modal'
export const IMPORT_MODAL = 'import-form-modal'

const EmployeeLeaveTypesPage = () => {
  const {
    getLeaveBalances,
    adjustQuota,
    bulkUpdateQuota
  } = leaveBalanceServices()
  const [search, setSearch] = useState<LeaveBalancesParam>()
  const [selected, setSelected] = useState<LeaveBalancesDataT>()
  const [modalOpen, setModalOpen] = useState<string>()
  const [leaveBalances, setLeaveBalances] = useState<LeaveBalancesResponse>()
  const [searchEmployee, setSearchEmployee] = useState<{query?: string, page?: number, per?: number}>()

  const onSelectData = (data: LeaveBalancesDataT, modal?: string) => {
    setSelected(data)
    modal && setModalOpen(QUOTA_MODAL)
  }

  const link = [
    {
      title: 'Company Settings',
      key: 'company_settings',
      href: companySettingsPath,
    },
    {
      title: 'Leave Balances',
      key: 'leave_balances',
      href: leaveBalancesPath,
    },
  ]

  const columns: ColumnT[] = [
    {
      label: 'Employee Name',
      key: 'name',
      width: 'sm:min-w-[180px] md:w-[20%] 2xl:w-[20%]',
    },
    {
      label: 'Business Leave',
      key: 'business',
      width: 'sm:min-w-[80px] md:w-[20%] 2xl:w-[15%]',
    },
    {
      label: 'Extra Vacation',
      key: 'extra_vacation',
      width: 'sm:min-w-[80px] md:w-[20%] 2xl:w-[15%]',
    },
    {
      label: 'Leave Without Pay',
      key: 'leave_without_pay',
      width: 'sm:min-w-[80px] md:w-[20%] 2xl:w-[15%]',
    },
    {
      label: 'Sick Leave',
      key: 'sick',
      width: 'sm:min-w-[80px] md:w-[20%] 2xl:w-[15%]',
    },
    {
      label: 'Vacation Leave',
      key: 'vacation',
      width: 'sm:min-w-[80px] md:w-[20%] 2xl:w-[15%]',
    },
    {
      label: 'Note',
      key: 'note',
      width: 'sm:min-w-[80px] md:w-[20%] 2xl:w-[15%]',
    },
    {
      label: '',
      key: '',
      width: 'w-[5%]',
      render: (row) => (
        <div className="flex gap-2">
          <label htmlFor={QUOTA_MODAL}>
            <FaPen
              className="text-icon-disabled hover:text-icon-subdued cursor-pointer text-[14px] mt-[2px]"
              onClick={() => onSelectData(row, QUOTA_MODAL)}
            />
          </label>
        </div>
      ),
    },
  ]

  const onCloseModal = (name: string) => {
    setModalOpen(undefined)
    closeModal([name])
  }

  const refetchData = async () => {
    modalOpen && onCloseModal(modalOpen)
    await getLeaveBalancesApi()
  }

  const getLeaveBalancesApi = async () => {
    const res = await getLeaveBalances(searchEmployee)
    setLeaveBalances(res)
  }

  const handleUpdateQuota = async (data: any, values: any) => {
    await adjustQuota({employee_leave_types: values, employee_id: selected?.id})
    refetchData()
  }

  const handleUpload = async (file: File) => {
    await bulkUpdateQuota(file)
    refetchData()
  }

  useEffect(() => {
    getLeaveBalancesApi()
  }, [searchEmployee])

  return (
    <>
      <div className="grid sm:grid-flow-col-1 md:grid-cols-[2fr_1fr] justify-between">
        <div>
          <h1 className="font-bold">Leave Balance {modalOpen}</h1>
          <BreadCrumb data={link} current="leave_balances_management" />
        </div>
      </div>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 items-center">
        <SearchEmployee
          total = {leaveBalances?.employees.length ?? 0}
          onChange={(filter) => setSearchEmployee({ ...filter, page: 1 })}
          searchEmployee={searchEmployee}
        />
        <div className="flex sm:flex-col md:flex-row gap-[8px] justify-end">
          <a
            href={'/admin/company_settings/leave_balances/export.csv'}
            className="btn btn-primary-outline btn-sm w-[130px]"
          >
            <ExportIcon className="fill-icon-primary ml-2" />
            &nbsp;Export
          </a>
          <label
            className="btn btn-primary-solid btn-sm sm:mb-4 md:mb-0 w-[150px]"
            onClick={() => {setModalOpen(IMPORT_MODAL)}}
            htmlFor={IMPORT_MODAL}
          >
            <BsPlus size={20} className="text-icon-on-interactive" />BULK UPDATE
          </label>
        </div>
      </div>
      <div className="overflow-auto">
        <Table columns={columns} data={leaveBalances?.employees ?? []} />
      </div>
      <div className="grid justify-end pt-4">
        <Pagination
          total_pages={leaveBalances?.meta.total_pages}
          per_page={leaveBalances?.meta.per}
          page={leaveBalances?.meta.page}
          onPageChange={(page) => setSearch({ ...search, page: page })}
          onPerPageChange={(value) =>
            setSearch({ ...search, per: value, page: 1 })
          }
        />
      </div>
      {modalOpen === QUOTA_MODAL && selected && (
        <EmployeeLeaveTypesFormModal
          onSubmit={handleUpdateQuota}
          modalName={QUOTA_MODAL}
          onClose={onCloseModal}
          value={{
            ...selected
          }}
        />
      )}
      {modalOpen === IMPORT_MODAL && (
        <ImportFormModal
          handleUpload = {handleUpload}
          modalName={IMPORT_MODAL}
          onClose={onCloseModal}
        />
      )}
    </>
  )
}

export default withApp(EmployeeLeaveTypesPage)
