import React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { ErrorText } from '../ErrorText'

type Props = {
  placeholder?: string
  iconStart?: JSX.Element
  iconEnd?: JSX.Element
  defaultValue?: string
  value?: string
  onChange?: (value: string) => void
  errorMessage?: string
  label?: string
  required?: boolean
  register?: UseFormRegister<FieldValues | any>
  name: string
  type?: string
  step?: number
  className?: string
}

export const Input = ({
  placeholder = 'Search',
  iconStart,
  iconEnd,
  defaultValue,
  value,
  onChange,
  errorMessage,
  label,
  required,
  register,
  name,
  type,
  step,
  className,
}: Props) => {
  return (
    <div className="grid grid-cols-1 w-inherit">
      <div className="relative w-inherit">
        {label && (
          <p>
            {label} {required && <span className="text-text-critical">*</span>}
          </p>
        )}
        {iconStart && (
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            {iconStart}
          </span>
        )}
        <input
          defaultValue={defaultValue}
          value={value}
          type={type ?? "text"}
          step={step ?? 1}
          min={0}
          className={`input ${iconStart ? 'pl-10' : 'pl-2'} ${
            iconEnd ? 'pr-10' : 'pr-0'
          } w-inherit className ${
            errorMessage
              ? 'border-text-critical'
              : '!border-border-default-subdued'
          }`}
          placeholder={placeholder}
          onChange={(e) => {
            onChange?.(e.target.value)
          }}
          {...register?.(name, { required })}
        />
        {iconEnd && (
          <span className="absolute inset-y-0 right-0 flex items-center pr-3">
            {iconEnd}
          </span>
        )}
      </div>
      {errorMessage && <ErrorText text={errorMessage} />}
    </div>
  )
}
