import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { z } from 'zod'

import { EMPLOYEE_ROLE_OPTIONS } from '@lib/constants'
import { CreateEmployeeParam, OptionT } from '@types'

import { userManagementServices } from '../services'
import { Input, Modal, Select, Toggle } from '@components'

type Props = {
  onSubmit: (payload: CreateEmployeeParam) => void
  modalName: string
  value?: CreateEmployeeParam
  onClose: (name: string) => void
}
export const UserFormModal = ({
  value,
  modalName,
  onClose,
  onSubmit,
}: Props) => {
  const { getTeams, getDirectManager } = userManagementServices()
  const [teamOptions, setTeamOptions] = useState<OptionT[]>([])
  const [directManagerOptions, setDirectManagerOptions] = useState<OptionT[]>(
    [],
  )

  const schema = z.object({
    email: z.string().min(1, 'email is required'),
    first_name: z.string().min(1, 'first name is required'),
    last_name: z.string().min(1, 'last name is required'),
    department_id: z.number({
      required_error: 'team is required',
    }),
    role: z.string({
      required_error: 'role is required',
    }),
    direct_manager_id: z.number({
      required_error: 'direct manager is required',
    }),
    status: z.string().optional(),
  })

  const {
    handleSubmit,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm<CreateEmployeeParam>({
    resolver: zodResolver(schema),
    defaultValues: value ? value : { status: 'inactive' },
  })

  const watchFields = watch(['department_id', 'status'])

  const getTeamsOptions = async () => {
    const res = await getTeams()
    const format: OptionT[] = res?.departments.map((item) => {
      return { label: item.name, value: item.id }
    })
    setTeamOptions(format)
  }

  const getDirectManagerOptions = async (id: number) => {
    const res = await getDirectManager({ department_id: id })
    setDirectManagerOptions(res)
  }

  useEffect(() => {
    if (watchFields[0]) {
      getDirectManagerOptions(watchFields[0])
    }
    setDirectManagerOptions([])
  }, [watchFields[0]])

  useEffect(() => {
    getTeamsOptions()
  }, [])

  return (
    <Modal
      name={modalName}
      title={`${value ? 'Edit User' : 'Create User'}`}
      onClose={() => onClose(modalName)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4 w-full">
          <Input
            placeholder="Email"
            label="Email"
            required
            name="email"
            register={register}
            errorMessage={errors.email?.message}
          />
          <div className="grid grid-cols-2 gap-4 w-full">
            <Input
              placeholder="First Name"
              label="First Name"
              name="first_name"
              required
              register={register}
              errorMessage={errors.first_name?.message}
            />
            <Input
              placeholder="Last Name"
              label="Last Name"
              name="last_name"
              required
              register={register}
              errorMessage={errors.last_name?.message}
            />
          </div>

          <Select
            label="Team"
            required
            placeholder="Select Team"
            value={value?.department_id}
            options={teamOptions}
            onChange={(value) =>
              setValue('department_id', value?.value as number)
            }
            errorMessage={errors.department_id?.message}
          />
          <Select
            label="Role"
            required
            value={value?.role}
            placeholder="Select Role"
            options={EMPLOYEE_ROLE_OPTIONS}
            onChange={(value) => setValue('role', value?.value as string)}
            errorMessage={errors.role?.message}
          />
          <Select
            label="Direct Manager"
            required
            value={value?.direct_manager_id}
            placeholder="Select Direct Manager"
            options={directManagerOptions}
            onChange={(value) =>
              setValue('direct_manager_id', value?.value as number)
            }
            errorMessage={errors.direct_manager_id?.message}
          />
          <Toggle
            isChecked={watchFields[1] === 'active'}
            description="Active"
            label="Status"
            name="status"
            onChange={(value) =>
              setValue('status', value ? 'active' : 'inactive')
            }
          />
        </div>

        <div className="w-full flex flex-row justify-end space-x-4 mt-[32px]">
          <button
            type="button"
            className="btn btn-primary-outline capitalize"
            onClick={() => onClose(modalName)}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary-solid">
            {value ? 'Save Changes' : 'Create'}
          </button>
        </div>
      </form>
    </Modal>
  )
}
