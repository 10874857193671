import { LeavePeriod } from '@types'
import dayjs from 'dayjs'
import { capitalize, startCase } from 'lodash'

export const FULL_DATE_TIME = 'DD/MM/YYYY HH:mm'
export const FULL_DATE_PARAM = 'YYYY-MM-DD'
export const FORMAT_DATE = 'YYYY/MM/DD'
export const DISPLAY_FORMAT_DATE = 'DD/MM/YYYY'

export const fotmatOptions = (value: string[] | number[], key?: string) => {
  return value.map((item) => ({
    label:
      typeof item === 'string'
        ? capitalize(item.split('_').join(' ')) + ' ' + (key ?? '')
        : `${item}` + ' ' + (key ?? ''),
    value: item,
  }))
}

export const formatDateTime = (date?: string, format?: string) => {
  return date ? dayjs(date).format(format ?? FULL_DATE_TIME) : undefined
}

export const formatDisplayDate = (
  starts_date: string,
  ends_date: string,
  datetime_range: string,
) => {
  if (dayjs(ends_date).isSame(dayjs(starts_date))) {
    return dayjs(ends_date).format(DISPLAY_FORMAT_DATE)
  }
  return datetime_range
}

export const transformKey = (key: string) => {
  return capitalize(key.split('_').join(' '))
}

export const formatPeriod = (period: string) =>
  startCase(period).replace(' ', '-')

export const formatDateWithPeriod = (date: string, period: LeavePeriod) => {
  return period === 'all_day' ? date : `${date} (${formatPeriod(period)})`
}

export const renderLeaveName = (key: string) => {
  const accept = [
    'working_from_office',
    'working_from_home',
    'working_remote',
    'leave_without_pay',
  ]
  const formatLeaves = transformKey(key)
  return accept.includes(key) ? formatLeaves : formatLeaves + ' ' + 'leave'
}

export const isWeekday = (date: Date) => {
  const day = dayjs(date).get('day')
  return day !== 0 && day !== 6
}
