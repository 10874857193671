import React, { useEffect, useState } from 'react'

import {
  formatDateTime,
  formatDateWithPeriod,
  renderLeaveName,
} from '@lib/helper'
import { SortT, TeamLeaveResponse, TeamLeaveParam, ColumnT } from '@types'

import { SearchFilter } from '../components/SearchFilter'
import { teamLeavesServices } from '../services'
import { Pagination, Status, Table } from '@components'

export const ActivityPage = () => {
  const { getTeamLeaves } = teamLeavesServices()
  const [search, setSearch] = useState<TeamLeaveParam>()
  const [leaves, setLeaves] = useState<TeamLeaveResponse>()

  const onChange = (value: SortT) => {
    setSearch({ ...search, sort_by: value, page: 1 })
  }

  const getTeamLeavesApprovalApi = async () => {
    const response = await getTeamLeaves(search)
    setLeaves(response)
  }

  const columns: ColumnT[] = [
    {
      label: 'Create Date',
      key: 'created_at',
      sort: true,
      width: 'md:w-[20%] 2xl:w-[10%]',
      render: (row) => formatDateTime(row.created_at) ?? '-',
    },
    {
      label: 'Submitted Employee',
      key: 'employee_name',
      width: 'md:w-[20%] 2xl:w-[10%]',
    },
    {
      label: 'Leave Type',
      key: 'leave_type_name',
      width: 'sm:min-w-[120px] md:w-[15%] 2xl:w-[10%]',
      render: (row) => renderLeaveName(row.leave_type_name),
    },
    {
      label: 'Leave Date',
      key: 'datetime_range',
      width: 'sm:min-w-[130px] md:w-[15%] 2xl:w-[20%]',
      render: (row) => formatDateWithPeriod(row.datetime_range, row.period),
    },
    {
      label: 'Status',
      key: 'status',
      width: 'w-[5%]',
      render: (row) => <Status status={row.status} type="leave" />,
    },

    {
      label: 'Approved By',
      key: 'reviewed_by_name',
      width: 'sm:min-w-[120px] md:w-[10%]',
    },
  ]

  useEffect(() => {
    getTeamLeavesApprovalApi()
  }, [search])

  return (
    <>
      <SearchFilter
        onChange={(filter) => setSearch({ ...filter, page: 1 })}
        search={search}
        totals={leaves?.meta.total_count ?? 0}
      />
      <div className="overflow-x-auto">
        <Table
          columns={columns}
          data={leaves?.leaves ?? []}
          sortBy={search?.sort_by ?? 'desc'}
          onSortChanged={onChange}
        />
      </div>
      <div className="grid justify-end pt-4">
        <Pagination
          total_pages={leaves?.meta.total_pages ?? 1}
          per_page={leaves?.meta.per}
          page={leaves?.meta.page}
          onPageChange={(page) => setSearch({ ...search, page: page })}
          onPerPageChange={(value) =>
            setSearch({ ...search, per: value, page: 1 })
          }
        />
      </div>
    </>
  )
}
