import React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

type Props = {
  isChecked?: boolean
  label?: string
  onChange?: (checked: boolean) => void
  required?: boolean
  register?: UseFormRegister<FieldValues | any>
  name: string
  description?: string
}
export const Toggle = ({
  isChecked,
  label,
  onChange,
  register,
  required,
  description,
  name,
}: Props) => {
  return (
    <div>
      {label && (
        <p>
          {label} {required && <span className="text-text-critical">*</span>}
        </p>
      )}
      <div
        className={`flex flex-row gap-4 p-[8px_12px] rounded border-[1px] w-full items-center`}
      >
        <input
          type="checkbox"
          className="toggle toggle-primary"
          checked={isChecked}
          {...register?.(name, { required })}
          onChange={(e) => onChange?.(e.target.checked)}
        />
        {description && <span className="label-text">{description}</span>}
      </div>
    </div>
  )
}
