import React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { ErrorText } from '../ErrorText'

type Props = {
  name: string
  register?: UseFormRegister<FieldValues | any>
  label?: string
  required?: boolean
  placeholder?: string
  labelSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  errorMessage?: string
  onChange?: (value: string) => void
}

export const TextAreaField = ({
  name,
  register,
  label,
  required = false,
  placeholder = 'Type here',
  labelSize = 'sm',
  errorMessage,
  onChange,
}: Props) => {
  return (
    <div className="form-control w-full">
      {label && (
        <label>
          <span className={`text-${labelSize}`}>{label}</span>
          {required && <span className="required-icon">*</span>}
        </label>
      )}
      <textarea
        {...register?.(name, { required })}
        placeholder={placeholder}
        className={`input input-bordered mt-2 w-full h-[120px] resize-none  ${
          errorMessage
            ? 'border-text-critical'
            : '!border-border-default-subdued'
        }`}
        onChange={(e) => onChange?.(e.target.value)}
      />
      {errorMessage && <ErrorText text={errorMessage} />}
    </div>
  )
}
