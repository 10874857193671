import __axios, { AxiosError } from 'axios'

const _axios = __axios.create({
  headers: {
    'X-CSRF-Token': document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content'),
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  responseType: 'json',
})

// We have our own lil' axios because it's easier
// to deal with `authenticity_token` in one-go.
export const axios = {
  get: (url: string, data: any) => {
    return _axios.get(url, data)
  },

  post: (url: string, data: any) => {
    return _axios.post(url, data)
  },

  patch: (url: string, data: any) => {
    return _axios.patch(url, data)
  },

  put: (url: string, data: any) => {
    return _axios.put(url, data)
  },

  delete: (url: string, data: any) => {
    return _axios.delete(url, {
      data,
    })
  },
  isAxiosError: __axios.isAxiosError,
}

// axios form data
const _axiosFormData = __axios.create({
  headers: {
    'X-CSRF-Token': document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content'),
    'Content-Type': 'multipart/form-data',
  },
  responseType: 'json',
})

export const axiosFormData = {
  post: (url: string, data: any) => {
    return _axiosFormData.post(url, data)
  },

  patch: (url: string, data: any) => {
    return _axiosFormData.patch(url, data)
  },
}
export const getErrorMessage = (error: AxiosError) => {
  return (error.response?.data as { error: string }).error
}
