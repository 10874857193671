import React, { useEffect, useState } from 'react'

import {
  formatDateTime,
  formatDateWithPeriod,
  renderLeaveName,
} from '@lib/helper'
import {
  ApproveTeamLeaveParam,
  ColumnT,
  SortT,
  TeamLeaveParam,
  TeamLeavePendingDataT,
  TeamLeavePendingResponse,
} from '@types'

import { SearchFilter } from '../components/SearchFilter'
import { teamLeavesServices } from '../services'
import { ApproveModal, LEAVE_APPROVE_FORM } from '../components/ApproveModal'
import { closeModal, Pagination, Table } from '@components'
import { leavesServices } from '@features/leaves/services'

export const PendingPage = () => {
  const { getTeamLeavesPending } = teamLeavesServices()
  const [leaves, setLeaves] = useState<TeamLeavePendingResponse>()
  const [search, setSearch] = useState<TeamLeaveParam>()
  const [selected, setSelected] = useState<TeamLeavePendingDataT>()
  const { approveLeave, rejectLeave } = leavesServices()

  const onChange = (value: SortT) => {
    setSearch({ ...search, sort_by: value, page: 1 })
  }

  const getTeamLeavesPendingApi = async () => {
    const response = await getTeamLeavesPending(search)
    setLeaves(response)
  }

  const handleApprove = async () => {
    if (selected?.id) await approveLeave(selected?.id)
  }

  const handleReject = async (reason: string) => {
    if (selected?.id) await rejectLeave(selected?.id, reason)
  }

  const onSubmitApprove = async (data: ApproveTeamLeaveParam) => {
    if (data.status === 'approve') {
      await handleApprove()
    } else {
      data.reason && (await handleReject(data.reason))
    }
    setSelected(undefined)
    getTeamLeavesPendingApi()
    closeModal([LEAVE_APPROVE_FORM])
  }

  useEffect(() => {
    getTeamLeavesPendingApi()
  }, [search])

  const columns: ColumnT[] = [
    {
      label: 'Submitted Date',
      key: 'created_at',
      sort: true,
      width: 'md:w-[15%] 2xl:w-[10%]',
      render: (row) => formatDateTime(row.created_at) ?? '-',
    },
    {
      label: 'Name',
      key: 'employee_name',
      width: 'sm:min-w-[120px] md:w-[30%] 2xl:w-[15%]',
    },
    {
      label: 'Team',
      key: 'department_name',
      width: 'sm:min-w-[120px] md:w-[15%] 2xl:w-[10%]',
    },
    {
      label: 'Leave Type',
      key: 'leave_type_name',
      width: 'sm:min-w-[120px] md:w-[15%] 2xl:w-[10%]',
      render: (row) => renderLeaveName(row.leave_type_name),
    },
    {
      label: 'Leave Date',
      key: 'datetime_range',
      width: 'sm:min-w-[130px] md:w-[15%] 2xl:w-[20%]',
      render: (row) => formatDateWithPeriod(row.datetime_range, row.period),
    },
    {
      label: 'Days',
      key: 'duration',
      width: 'w-[5%]',
    },
    {
      label: 'Details',
      key: 'reason',
      width: 'sm:min-w-[120px] md:w-[10%]',
      render: (row) => (
        <div className="text-wrap sm:max-w-[120px] lg:max-w-[180px] 2xl:max-w-[320px]">
          {row.reason ?? '-'}
        </div>
      ),
    },
    {
      label: '',
      key: 'id',
      width: 'w-[10%]',
      render: (row) => (
        <label
          htmlFor={LEAVE_APPROVE_FORM}
          className="btn btn-primary-solid btn-xs"
          onClick={() => setSelected(row)}
        >
          Review
        </label>
      ),
    },
  ]
  return (
    <>
      <SearchFilter
        onChange={(filter) => setSearch({ ...filter, page: 1 })}
        search={search}
        totals={leaves?.meta.total_count ?? 0}
        isDisplaystatus={false}
      />
      <div className="overflow-x-auto">
        <Table
          columns={columns}
          data={leaves?.pending_leaves ?? []}
          sortBy={search?.sort_by ?? 'desc'}
          onSortChanged={onChange}
        />
      </div>

      <div className="grid justify-end pt-4">
        <Pagination
          total_pages={leaves?.meta.total_pages ?? 1}
          per_page={leaves?.meta.per}
          page={leaves?.meta.page}
          onPageChange={(page) => setSearch({ ...search, page: page })}
          onPerPageChange={(value) =>
            setSearch({ ...search, per: value, page: 1 })
          }
        />
      </div>
      {selected && <ApproveModal data={selected} onSubmit={onSubmitApprove} />}
    </>
  )
}
