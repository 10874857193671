import { axios } from '@lib/helper'
import { mePath, logoutPath } from '@path'
import { MeResponsesT } from '@types'

export const authServices = () => {
  return {
    getMe: async (): Promise<MeResponsesT> => {
      const res = await axios.get(mePath, {})
      return res.data || {}
    },
    logout: async () => {
      await axios.delete(logoutPath, {})
      window.location.href = '/leaves'
    },
  }
}
