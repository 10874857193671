import { Input } from '@components'
import React from 'react'

type Props = {
  onChange: (filter?: {query: string}) => void
  total: number
  searchEmployee?: {query: string}
}
export const SearchEmployee = ({ onChange, total, searchEmployee }: Props) => {
  return (
    <div className="grid w-full sm:grid-cols-1 md:grid-cols-[220px_220px_220px_auto] gap-2 my-4 md:items-center sm:items-baseline">
      <Input
        name="query"
        value={searchEmployee?.query}
        placeholder={'Search'}
        onChange={(value) =>
          onChange({ ...searchEmployee, query: value as string})
        }
      />
      <div className="font-bold text-sm">
        Matching: <span className="font-normal">{total} items</span>
      </div>
    </div>
  )
}
