import React from 'react'

import { LEAVE_STATUS_OPTIONS, LEAVE_TYPE_OPTIONS } from '@lib/constants'
import { formatDateTime, FULL_DATE_PARAM } from '@lib/helper'
import { LeaveStatus, TeamLeaveParam } from '@types'

import { Calendar, Select } from '@components'
type Props = {
  onChange: (filter: TeamLeaveParam) => void
  totals: number
  search?: TeamLeaveParam
  isDisplaystatus?: boolean
}

export const SearchFilter = ({
  onChange,
  totals,
  search,
  isDisplaystatus = true,
}: Props) => {
  return (
    <div
      className={`grid w-full sm:grid-cols-1 ${
        isDisplaystatus
          ? 'md:grid-cols-[220px_220px_220px_auto]'
          : 'md:grid-cols-[220px_220px_auto]'
      } gap-2 my-4 md:items-center sm:items-baseline`}
    >
      <Select
        onChange={(value) =>
          onChange({ ...search, leave_type: value?.value as string })
        }
        options={LEAVE_TYPE_OPTIONS}
        value={search?.leave_type}
      />
      <Calendar
        placeholder="All Date Time Range"
        onChange={(value) => {
          onChange({
            ...search,
            starts_date: formatDateTime(
              value?.[0] as unknown as string,
              FULL_DATE_PARAM,
            ),
            ends_date: formatDateTime(
              value?.[1] as unknown as string,
              FULL_DATE_PARAM,
            ),
          })
        }}
      />
      {isDisplaystatus && (
        <Select
          onChange={(value) =>
            onChange({
              ...search,
              status:
                value?.value === '' ? undefined : (value?.value as LeaveStatus),
            })
          }
          options={LEAVE_STATUS_OPTIONS}
          value={search?.status}
        />
      )}
      <div className="font-bold text-sm">
        Matching: <span className="font-normal">{totals} items</span>
      </div>
    </div>
  )
}
