import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import DatePicker, { ReactDatePicker } from 'react-datepicker'
import { useMediaQuery } from 'react-responsive'
import { MdCalendarToday } from 'react-icons/md'
import dayjs from 'dayjs'

import { DISPLAY_FORMAT_DATE, FORMAT_DATE, isWeekday } from '@lib/helper'
import { DateRangeValue } from '@types'

import {
  getThisMonthValues,
  getThisWeekValues,
  getTodayValues,
  getYesterdayValues,
} from './getDate'
import { Input } from '../Input'

type Props = {
  date?: DateRangeValue
  onChange: (date?: DateRangeValue) => void
  errorMessage?: string
  placeholder?: string
  minDate?: Date
  excludeDates?: Date[]
}

export const Calendar = ({
  onChange,
  date,
  errorMessage,
  placeholder,
  minDate,
  excludeDates,
}: Props) => {
  const [selected, setSelected] = useState<DateRangeValue>()
  const calendarRef = useRef<ReactDatePicker>(null)
  const { theme } = require('@config/tailwind.config')
  const { screens } = theme.extend
  const isMobile = useMediaQuery({
    query: `(max-width: ${screens.md})`,
  })

  const checkDateWithMinDate = (
    selectedDate: DateRangeValue,
  ): DateRangeValue | undefined => {
    if (dayjs(selectedDate[0]).isAfter(minDate) || !minDate) {
      return selectedDate
    } else if (
      (dayjs(selectedDate[0]).isBefore(minDate) &&
        dayjs(selectedDate[1]).isAfter(minDate)) ||
      dayjs(selectedDate[1]).startOf('day').isSame(minDate, 'day')
    ) {
      return [new Date(dayjs().format(FORMAT_DATE)), selectedDate[1]]
    }
    return undefined
  }

  const onClickAction = (key: string) => {
    let data
    switch (key) {
      case 'today':
        onSave(getTodayValues())
        break
      case 'yesterday':
        data = checkDateWithMinDate(getYesterdayValues())
        onSave(data)
        break
      case 'week':
        data = checkDateWithMinDate(getThisWeekValues())
        onSave(data)
        break
      case 'month':
        data = checkDateWithMinDate(getThisMonthValues())
        onSave(data)
        break
      case 'all':
        onSave(undefined)
        break
    }
    onClose()
  }

  const ACTION = [
    { title: 'Today', key: 'today' },
    { title: 'Yesterday', key: 'yesterday' },
    { title: 'This Week', key: 'week' },
    { title: 'This Month', key: 'month' },
    { title: 'All', key: 'all' },
  ]

  const CustomeInput = forwardRef(({ onClick, value }, ref) => {
    let display = placeholder
    if (value) {
      const date = value?.split(' - ')
      if (date.length > 1) {
        display = `${dayjs(date[0]).format(DISPLAY_FORMAT_DATE)} - ${
          date[1] !== '' ? dayjs(date[1]).format(DISPLAY_FORMAT_DATE) : ''
        }`
      }
    }

    return (
      <div onClick={onClick} ref={ref} className="w-inherit">
        <Input
          name="calendar"
          iconEnd={<MdCalendarToday />}
          errorMessage={errorMessage}
          value={display}
          placeholder={placeholder ?? 'Select'}
        />
      </div>
    )
  })

  useEffect(() => {
    setSelected(date)
  }, [date])

  const onSave = (date?: DateRangeValue) => {
    setSelected(date)
  }

  const onClose = () => {
    calendarRef?.current?.setOpen(false)
  }

  const view = useMemo(() => {
    return isMobile ? 1 : 2
  }, [isMobile])

  return (
    <DatePicker
      selectsRange={true}
      startDate={selected?.[0]}
      endDate={selected?.[1]}
      onCalendarClose={() => onChange?.(selected)}
      ref={calendarRef}
      filterDate={isWeekday}
      minDate={minDate}
      onChange={(update: DateRangeValue) => {
        onSave(update)
      }}
      onChangeRaw={(event) => {
        event.preventDefault()
      }}
      customInput={<CustomeInput />}
      monthsShown={view}
      excludeDates={excludeDates ?? []}
    >
      <div className="grid absolute left-[20px] gap-4">
        {ACTION.map((item, index) => (
          <button
            key={index}
            className={`btn-underline ${
              !selected && item.key === 'all'
                ? 'text-text-primary-default'
                : 'text-tex-default'
            }`}
            onClick={() => onClickAction(item.key)}
          >
            {item.title}
          </button>
        ))}
      </div>
    </DatePicker>
  )
}
