import React, { useEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { FaPen } from 'react-icons/fa'
import { IoMdTrash } from 'react-icons/io'

import {
  SortT,
  EmployeeDataT,
  EmployeeParam,
  EmployeeResponse,
  CreateEmployeeParam,
  ColumnT,
  OptionT,
} from '@types'

import { formatTeamOptions } from '.'
import { SearchFilter } from '../components/SearhFilter'
import { UserFormModal } from '../components/UserFormModal'
import {
  USER_CREATE_MODAL,
  USER_DELETE_MODAL,
  USER_EDIT_MODAL,
} from '../constants'
import { DeleteUserModal } from '../components/DeleteUserModal'
import { userManagementServices } from '../services'
import { closeModal, ExportIcon, Pagination, Status, Table } from '@components'
import { omit } from 'lodash'

export const UserPage = () => {
  const {
    getEmployees,
    createEmployee,
    updateEmployee,
    deleteEmployee,
    getTeams,
  } = userManagementServices()
  const [search, setSearch] = useState<EmployeeParam>()
  const [selected, setSelected] = useState<EmployeeDataT>()
  const [modal, setModal] = useState<string>()
  const [employees, setEmployees] = useState<EmployeeResponse>()
  const [teamOptions, setTeamOptions] = useState<OptionT[]>()

  const onChange = (value: SortT) => {
    setSearch({ ...search, sort_by: value })
  }

  const onSelectData = (data: EmployeeDataT, modal?: string) => {
    setSelected(data)
    modal && setModal(modal)
  }

  const getTeamsOptions = async () => {
    const res = await getTeams()
    const format = formatTeamOptions(res.departments)
    setTeamOptions(format as OptionT[])
  }

  useEffect(() => {
    getTeamsOptions()
  }, [])

  const columns: ColumnT[] = [
    {
      label: 'Email',
      key: 'email',
      sort: true,
      width: 'md:w-[20%] 2xl:w-[10%]',
    },
    {
      label: 'Name',
      key: 'name',
      width: 'sm:min-w-[180px] md:w-[20%] 2xl:w-[15%]',
    },
    {
      label: 'Role',
      key: 'role',
      width: 'sm:min-w-[80px] md:w-[10%] 2xl:w-[15%]',
      render: (row) => <p className="capitalize">{row.role}</p>,
    },
    {
      label: 'Team',
      key: 'department_name',
      width: 'sm:min-w-[120px] md:w-[15%] 2xl:w-[10%]',
    },
    {
      label: 'Direct Manager',
      key: 'direct_manager_name',
      width: 'sm:min-w-[180px] md:w-[20%] 2xl:w-[15%]',
    },
    {
      label: 'Status',
      key: 'datetime_range',
      width: 'sm:min-w-[80px] md:w-[15%] 2xl:w-[20%]',
      render: (row) => <Status status={row.status} type="employee" />,
    },
    {
      label: '',
      key: '',
      width: 'w-[5%]',
      render: (row) => (
        <div className="flex gap-2">
          <label htmlFor={USER_EDIT_MODAL}>
            <FaPen
              className="text-icon-disabled hover:text-icon-subdued cursor-pointer text-[14px] mt-[2px]"
              onClick={() => onSelectData(row, USER_EDIT_MODAL)}
            />
          </label>
          <label htmlFor={USER_DELETE_MODAL}>
            <IoMdTrash
              className="text-icon-disabled  hover:text-icon-subdued cursor-pointer text-[18px]"
              onClick={() => onSelectData(row, USER_DELETE_MODAL)}
            />
          </label>
        </div>
      ),
    },
  ]

  const onCloseModal = (name: string) => {
    setModal(undefined)
    closeModal([name])
  }

  const getEmployeesApi = async () => {
    const res = await getEmployees(search)
    setEmployees(res)
  }

  const handleCreateEmployee = async (param: CreateEmployeeParam) => {
    await createEmployee(param)
    refetchData()
  }

  const handleUpdateEmployee = async (param: CreateEmployeeParam) => {
    if (selected) {
      await updateEmployee({ ...param, id: selected.id })
      refetchData()
    }
  }

  const handleDeleteEmployee = async () => {
    if (selected) {
      await deleteEmployee(selected.id)
      refetchData()
    }
  }

  const refetchData = () => {
    modal && onCloseModal(modal)
    getEmployeesApi()
  }

  useEffect(() => {
    getEmployeesApi()
  }, [search])

  return (
    <>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 items-center">
        <SearchFilter
          onChange={setSearch}
          totals={employees?.meta.total_count ?? 0}
          search={search}
          teamOptions={teamOptions ?? []}
        />
        <div className="flex sm:flex-col md:flex-row gap-[8px] justify-end">
          <a
            href={'/admin/employees/export.csv'}
            className="btn btn-primary-outline btn-sm w-[130px]"
          >
            <ExportIcon className="fill-icon-primary ml-2" />
            &nbsp;Export
          </a>
          <label
            className="btn btn-primary-solid btn-sm sm:mb-4 md:mb-0 w-[130px]"
            onClick={() => {
              setModal(USER_CREATE_MODAL)
              setSelected(undefined)
            }}
            htmlFor={USER_CREATE_MODAL}
          >
            <BsPlus size={20} className="text-icon-on-interactive" /> Create
            User
          </label>
        </div>
      </div>
      <div className="overflow-auto">
        <Table
          columns={columns}
          data={employees?.employees ?? []}
          sortBy={search?.sort_by}
          onSortChanged={onChange}
        />
      </div>
      <div className="grid justify-end pt-4">
        <Pagination
          total_pages={employees?.meta.total_pages}
          per_page={employees?.meta.per}
          page={employees?.meta.page}
          onPageChange={(page) => setSearch({ ...search, page: page })}
          onPerPageChange={(value) =>
            setSearch({ ...search, per: value, page: 1 })
          }
        />
      </div>
      {selected && (
        <DeleteUserModal data={selected} onSubmit={handleDeleteEmployee} />
      )}
      {modal === USER_CREATE_MODAL && (
        <UserFormModal
          onSubmit={handleCreateEmployee}
          modalName={modal}
          onClose={onCloseModal}
        />
      )}
      {modal === USER_EDIT_MODAL && selected && (
        <UserFormModal
          onSubmit={handleUpdateEmployee}
          modalName={modal}
          onClose={onCloseModal}
          value={{
            ...selected,
            first_name: selected.name.split(' ')[0],
            last_name: selected.name.split(' ')[1],
          }}
        />
      )}
    </>
  )
}
