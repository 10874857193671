import React, { SVGAttributes } from 'react'
export const HomeIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M8.0001 16.4546V11.4546H12.0001V16.4546C12.0001 17.0046 12.4501 17.4546 13.0001 17.4546H16.0001C16.5501 17.4546 17.0001 17.0046 17.0001 16.4546V9.45456H18.7001C19.1601 9.45456 19.3801 8.88456 19.0301 8.58456L10.6701 1.05456C10.2901 0.714561 9.7101 0.714561 9.3301 1.05456L0.970098 8.58456C0.630098 8.88456 0.840098 9.45456 1.3001 9.45456H3.0001V16.4546C3.0001 17.0046 3.4501 17.4546 4.0001 17.4546H7.0001C7.5501 17.4546 8.0001 17.0046 8.0001 16.4546Z" />
    </svg>
  )
}

export const MyLeaveIcon = (props: SVGAttributes<SVGAElement>) => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M16 2.45459H11.82C11.4 1.29459 10.3 0.45459 9 0.45459C7.7 0.45459 6.6 1.29459 6.18 2.45459H2C0.9 2.45459 0 3.35459 0 4.45459V18.4546C0 19.5546 0.9 20.4546 2 20.4546H16C17.1 20.4546 18 19.5546 18 18.4546V4.45459C18 3.35459 17.1 2.45459 16 2.45459ZM9 2.45459C9.55 2.45459 10 2.90459 10 3.45459C10 4.00459 9.55 4.45459 9 4.45459C8.45 4.45459 8 4.00459 8 3.45459C8 2.90459 8.45 2.45459 9 2.45459ZM9 6.45459C10.66 6.45459 12 7.79459 12 9.45459C12 11.1146 10.66 12.4546 9 12.4546C7.34 12.4546 6 11.1146 6 9.45459C6 7.79459 7.34 6.45459 9 6.45459ZM15 18.4546H3V17.0546C3 15.0546 7 13.9546 9 13.9546C11 13.9546 15 15.0546 15 17.0546V18.4546Z" />
    </svg>
  )
}

export const TeamLeaveIcon = (props: SVGAttributes<SVGAElement>) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M12 20.4546L11.14 19.5946C9.96 18.4146 9.97 16.4946 11.16 15.3346L12 14.5146C11.61 14.4746 11.32 14.4546 11 14.4546C8.33 14.4546 3 15.7946 3 18.4546V20.4546H12ZM11 12.4546C13.21 12.4546 15 10.6646 15 8.45459C15 6.24459 13.21 4.45459 11 4.45459C8.79 4.45459 7 6.24459 7 8.45459C7 10.6646 8.79 12.4546 11 12.4546Z" />
      <path d="M16.1798 20.2346C15.7898 20.6246 15.1498 20.6246 14.7598 20.2346L12.6898 18.1446C12.3098 17.7546 12.3098 17.1346 12.6898 16.7546L12.6998 16.7446C13.0898 16.3546 13.7198 16.3546 14.0998 16.7446L15.4698 18.1146L19.8998 13.6546C20.2898 13.2646 20.9198 13.2646 21.3098 13.6546L21.3198 13.6646C21.6998 14.0546 21.6998 14.6746 21.3198 15.0546L16.1798 20.2346Z" />
    </svg>
  )
}

export const UserIcon = (props: SVGAttributes<SVGAElement>) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M12 2.45459C6.48 2.45459 2 6.93459 2 12.4546C2 17.9746 6.48 22.4546 12 22.4546C17.52 22.4546 22 17.9746 22 12.4546C22 6.93459 17.52 2.45459 12 2.45459ZM15.61 8.79459C16.68 8.79459 17.54 9.65459 17.54 10.7246C17.54 11.7946 16.68 12.6546 15.61 12.6546C14.54 12.6546 13.68 11.7946 13.68 10.7246C13.67 9.65459 14.54 8.79459 15.61 8.79459ZM9.61 7.21459C10.91 7.21459 11.97 8.27459 11.97 9.57459C11.97 10.8746 10.91 11.9346 9.61 11.9346C8.31 11.9346 7.25 10.8746 7.25 9.57459C7.25 8.26459 8.3 7.21459 9.61 7.21459ZM9.61 16.3446V20.0946C7.21 19.3446 5.31 17.4946 4.47 15.1346C5.52 14.0146 8.14 13.4446 9.61 13.4446C10.14 13.4446 10.81 13.5246 11.51 13.6646C9.87 14.5346 9.61 15.6846 9.61 16.3446ZM12 20.4546C11.73 20.4546 11.47 20.4446 11.21 20.4146V16.3446C11.21 14.9246 14.15 14.2146 15.61 14.2146C16.68 14.2146 18.53 14.6046 19.45 15.3646C18.28 18.3346 15.39 20.4546 12 20.4546Z" />
    </svg>
  )
}

export const LogsIcon = (props: SVGAttributes<SVGAElement>) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" className={props.className}>
      <path d="M4 10.9546C3.17 10.9546 2.5 11.6246 2.5 12.4546C2.5 13.2846 3.17 13.9546 4 13.9546C4.83 13.9546 5.5 13.2846 5.5 12.4546C5.5 11.6246 4.83 10.9546 4 10.9546ZM4 4.95459C3.17 4.95459 2.5 5.62459 2.5 6.45459C2.5 7.28459 3.17 7.95459 4 7.95459C4.83 7.95459 5.5 7.28459 5.5 6.45459C5.5 5.62459 4.83 4.95459 4 4.95459ZM4 16.9546C3.17 16.9546 2.5 17.6346 2.5 18.4546C2.5 19.2746 3.18 19.9546 4 19.9546C4.82 19.9546 5.5 19.2746 5.5 18.4546C5.5 17.6346 4.83 16.9546 4 16.9546ZM7 19.4546H21V17.4546H7V19.4546ZM7 13.4546H21V11.4546H7V13.4546ZM7 5.45459V7.45459H21V5.45459H7Z" />
    </svg>
  )
}

export const SettingsIcon = (props: SVGAttributes<SVGAElement>) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" className={props.className}>
      <path d="M19.4298 13.4346C19.4698 13.1146 19.4998 12.7946 19.4998 12.4546C19.4998 12.1146 19.4698 11.7946 19.4298 11.4746L21.5398 9.82459C21.7298 9.67459 21.7798 9.40459 21.6598 9.18459L19.6598 5.72459C19.5398 5.50459 19.2698 5.42459 19.0498 5.50459L16.5598 6.50459C16.0398 6.10459 15.4798 5.77459 14.8698 5.52459L14.4898 2.87459C14.4598 2.63459 14.2498 2.45459 13.9998 2.45459H9.99984C9.74984 2.45459 9.53984 2.63459 9.50984 2.87459L9.12984 5.52459C8.51984 5.77459 7.95984 6.11459 7.43984 6.50459L4.94984 5.50459C4.71984 5.41459 4.45984 5.50459 4.33984 5.72459L2.33984 9.18459C2.20984 9.40459 2.26984 9.67459 2.45984 9.82459L4.56984 11.4746C4.52984 11.7946 4.49984 12.1246 4.49984 12.4546C4.49984 12.7846 4.52984 13.1146 4.56984 13.4346L2.45984 15.0846C2.26984 15.2346 2.21984 15.5046 2.33984 15.7246L4.33984 19.1846C4.45984 19.4046 4.72984 19.4846 4.94984 19.4046L7.43984 18.4046C7.95984 18.8046 8.51984 19.1346 9.12984 19.3846L9.50984 22.0346C9.53984 22.2746 9.74984 22.4546 9.99984 22.4546H13.9998C14.2498 22.4546 14.4598 22.2746 14.4898 22.0346L14.8698 19.3846C15.4798 19.1346 16.0398 18.7946 16.5598 18.4046L19.0498 19.4046C19.2798 19.4946 19.5398 19.4046 19.6598 19.1846L21.6598 15.7246C21.7798 15.5046 21.7298 15.2346 21.5398 15.0846L19.4298 13.4346ZM11.9998 15.9546C10.0698 15.9546 8.49984 14.3846 8.49984 12.4546C8.49984 10.5246 10.0698 8.95459 11.9998 8.95459C13.9298 8.95459 15.4998 10.5246 15.4998 12.4546C15.4998 14.3846 13.9298 15.9546 11.9998 15.9546Z" />
    </svg>
  )
}

export const ExportIcon = (props: SVGAttributes<SVGAElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H7.16667C7.625 2.16667 8 1.79167 8 1.33333C8 0.875 7.625 0.5 7.16667 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8.83333C15.5 8.375 15.125 8 14.6667 8C14.2083 8 13.8333 8.375 13.8333 8.83333V13C13.8333 13.4583 13.4583 13.8333 13 13.8333ZM9.66667 1.33333C9.66667 1.79167 10.0417 2.16667 10.5 2.16667H12.6583L5.05 9.775C4.725 10.1 4.725 10.625 5.05 10.95C5.375 11.275 5.9 11.275 6.225 10.95L13.8333 3.34167V5.5C13.8333 5.95833 14.2083 6.33333 14.6667 6.33333C15.125 6.33333 15.5 5.95833 15.5 5.5V0.5H10.5C10.0417 0.5 9.66667 0.875 9.66667 1.33333Z" />
    </svg>
  )
}
