export const adminPermission = [
  'home',
  'my_leaves',
  'user_management',
  'activity_logs',
  'company_settings',
]
export const managerPermission = [
  'home',
  'my_leaves',
  'team_leaves',
  'activity_logs',
  'company_settings',
]
export const userPermission = ['home', 'my_leaves']

export const ownerPermission = [
  'home',
  'my_leaves',
  'team_leaves',
  'user_management',
  'activity_logs',
  'company_settings',
]
