import React, { useEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { FaPen } from 'react-icons/fa'
import { IoMdTrash } from 'react-icons/io'
import { omit } from 'lodash'

import {
  ColumnT,
  CreateTeamParam,
  SortT,
  TeamResponse,
  TeamDataT,
  EmployeeParam,
  OptionT,
} from '@types'

import { formatTeamOptions } from '.'
import {
  TEAM_CREATE_MODAL,
  TEAM_DELETE_MODAL,
  TEAM_EDIT_MODAL,
} from '../constants'
import { SearchFilter } from '../components/SearhFilter'
import { TeamFormModal } from '../components/TeamFormModal'
import { userManagementServices } from '../services'
import { DeleteTeamModal } from '../components/DeleteTeamModal'
import { Pagination, Table, closeModal } from '@components'
import { formatDateTime } from '@lib/helper'

export const TeamPage = () => {
  const { getTeams, createTeam, updateTeam, deleteTeam } =
    userManagementServices()
  const [selected, setSelected] = useState<TeamDataT>()
  const [modal, setModal] = useState<string>()
  const [teams, setTeams] = useState<TeamResponse>()
  const [search, setSearch] = useState<EmployeeParam>()
  const [teamOptions, setTeamOptions] = useState<OptionT[]>()

  const onSelectData = (data: TeamDataT, modal?: string) => {
    setSelected(data)
    modal && setModal(modal)
  }

  const onChange = (value: SortT) => {
    setSearch({ ...search, sort_by: value })
  }

  const columns: ColumnT[] = [
    {
      label: 'Team Name',
      key: 'name',
      sort: true,
      width: 'sm:min-w-[180px] md:w-[20%] 2xl:w-[20%]',
    },
    {
      label: 'Members',
      key: 'employee_count',
      width: 'sm:min-w-[80px] md:w-[20%] 2xl:w-[15%]',
    },
    {
      label: 'Last Modified',
      key: 'updated_at',
      width: 'sm:min-w-[180px] md:w-[20%] 2xl:w-[20%]',
      render: (row) => formatDateTime(row.updated_at) ?? '-',
    },
    {
      label: '',
      key: '',
      width: 'w-[5%]',
      render: (row) => (
        <div className="flex gap-2">
          <label htmlFor={TEAM_EDIT_MODAL}>
            <FaPen
              className="text-icon-disabled hover:text-icon-subdued cursor-pointer text-[14px] mt-[2px]"
              onClick={() => onSelectData(row, TEAM_EDIT_MODAL)}
            />
          </label>
          <label htmlFor={TEAM_DELETE_MODAL}>
            <IoMdTrash
              className="text-icon-disabled  hover:text-icon-subdued cursor-pointer text-[18px]"
              onClick={() => onSelectData(row, TEAM_DELETE_MODAL)}
            />
          </label>
        </div>
      ),
    },
  ]

  const handleCreateTeam = async (param: CreateTeamParam) => {
    await createTeam(param)
    refetchData()
  }

  const handleUpdateTeam = async (param: CreateTeamParam) => {
    if (selected) {
      await updateTeam({ ...param, id: selected.id })
      refetchData()
    }
  }

  const handleDeleteEmployee = async () => {
    if (selected) {
      await deleteTeam(selected.id)
      refetchData()
    }
  }

  const refetchData = async () => {
    modal && onCloseModal(modal)
    setTeamOptions(undefined)
    await getTeamsApi()
  }

  const onCloseModal = (name: string) => {
    setModal(undefined)
    closeModal([name])
  }

  const getTeamsApi = async () => {
    const res = await getTeams({
      ...omit(search, 'department_id'),
      id: search?.department_id,
    })
    setTeams(res)
  }

  useEffect(() => {
    getTeamsApi()
  }, [search])

  useEffect(() => {
    if (!teamOptions || teamOptions.length === 1) {
      const format = formatTeamOptions(teams?.departments)
      setTeamOptions(format as OptionT[])
    }
  }, [teams])

  return (
    <>
      <div className="grid sm:grid-cols-1 md:grid-cols-[2fr_130px] items-center">
        <SearchFilter
          search={search}
          onChange={(value) => {
            setSearch(value)
          }}
          totals={teams?.meta.total_count ?? 0}
          teamOptions={teamOptions ?? []}
        />
        <label
          className="btn btn-primary-solid btn-sm sm:mb-4 md:mb-0"
          onClick={() => {
            setModal(TEAM_CREATE_MODAL)
            setSelected(undefined)
          }}
          htmlFor={TEAM_CREATE_MODAL}
        >
          <BsPlus size={20} className="text-icon-on-interactive" /> Create Team
        </label>
      </div>
      <div className="overflow-auto">
        <Table
          columns={columns}
          data={teams?.departments ?? []}
          sortBy={search?.sort_by}
          onSortChanged={onChange}
        />
      </div>
      <div className="grid justify-end pt-4">
        <Pagination
          total_pages={teams?.meta.total_pages}
          per_page={teams?.meta.per}
          page={teams?.meta.page}
          onPageChange={(page) => setSearch({ ...search, page: page })}
          onPerPageChange={(value) =>
            setSearch({ ...search, per: value, page: 1 })
          }
        />
      </div>

      {modal === TEAM_CREATE_MODAL && (
        <TeamFormModal
          onSubmit={handleCreateTeam}
          modalName={modal}
          onClose={onCloseModal}
        />
      )}
      {selected && modal === TEAM_EDIT_MODAL && (
        <TeamFormModal
          onSubmit={handleUpdateTeam}
          modalName={modal}
          onClose={onCloseModal}
          value={{ name: selected.name }}
        />
      )}
      {selected && (
        <DeleteTeamModal data={selected} onSubmit={handleDeleteEmployee} />
      )}
    </>
  )
}
