import React from 'react'
import { withApp } from '@features/layout'
import { BreadCrumb, ExportIcon, Pagination, Status, Table } from '@components'
import { companySettingsPath, leaveTypesPath } from '@path'
import { SearchFilter } from '../components/SearchFilter'
import { ColumnT } from '@types'
import { FaPen } from 'react-icons/fa'

const LeaveTypesPage = () => {
  const link = [
    {
      title: 'Company Settings',
      key: 'company_settings',
      href: companySettingsPath,
    },
    {
      title: 'Leave Types Management',
      key: 'leave_types_management',
      href: leaveTypesPath,
    },
  ]

  const columns: ColumnT[] = [
    {
      label: 'Leave Type Name',
      key: 'leave_type_name',
      width: 'sm:min-w-[180px] md:w-[20%] 2xl:w-[20%]',
    },
    {
      label: 'Default Quota',
      key: 'qouta',
      width: 'sm:min-w-[80px] md:w-[20%] 2xl:w-[15%]',
    },
    {
      label: 'Maximum Quota',
      key: 'max_qouta',
      width: 'sm:min-w-[180px] md:w-[20%] 2xl:w-[20%]',
    },
    {
      label: 'Status',
      key: 'status',
      width: 'w-[5%]',
      render: (row) => <Status status={row.status} type="employee" />,
    },
    {
      label: '',
      key: '',
      width: 'w-[5%]',
      render: (row) => (
        <label>
          <FaPen className="text-icon-disabled hover:text-icon-subdued cursor-pointer text-[14px] mt-[2px]" />
        </label>
      ),
    },
  ]
  return (
    <>
      <div className="grid sm:grid-flow-col-1 md:grid-cols-[2fr_1fr] justify-between">
        <div>
          <h1 className="font-bold">Leave Types Management</h1>
          <BreadCrumb data={link} current="leave_types_management" />
        </div>
        <div className="flex sm:flex-col md:flex-row justify-end gap-4 items-center">
          <button className="btn btn-primary-outline btn-md sm:w-full md:w-[118px]">
            <ExportIcon className="fill-icon-primary ml-2" />
            &nbsp;Export
          </button>
          <label className="btn btn-primary-solid btn-md sm:w-full md:w-[157px]">
            + &nbsp; Create Type
          </label>
        </div>
      </div>
      <div className="card p-[16px_20px] mt-[24px] gap-[18px]">
        <SearchFilter totals={0} />
        <div className="grid justify-end">
          <Pagination
            total_pages={1}
            per_page={25}
            page={1}
            onPageChange={(page) => {}}
            onPerPageChange={(value) => {}}
          />
        </div>
        <Table columns={columns} data={[]} />
      </div>
    </>
  )
}

export default withApp(LeaveTypesPage)
