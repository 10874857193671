import { showToast } from '@components'
import { axios, getErrorMessage } from '@lib/helper'

import { calendarPath, holidayPath, teamsPathApi } from '@path'
import {
  CalendarParams,
  CalendarResponse,
  HolidayResponse,
  TeamParam,
  TeamResponse,
} from '@types'
import { AxiosError } from 'axios'

export const CalendarServices = () => {
  return {
    getCalendar: async (payload: CalendarParams): Promise<CalendarResponse> => {
      const res = await axios.get(calendarPath, { params: payload })
      return res.data || {}
    },
    getTeams: async (payload?: TeamParam): Promise<TeamResponse> => {
      const res = await axios.get(teamsPathApi, { params: payload })
      return res.data || {}
    },
  }
}
