import React, { useEffect, useState } from 'react'
import { withApp } from '@features/layout'
import { formatDateTime } from '@lib/helper'
import {
  ActivityLogsParam,
  ActivityLogsResponse,
  ColumnT,
} from '@types'
import { activityLogServices } from '../services'
import { Pagination, Table } from '@components'
import { SearchFilter } from '../components/SearchFilter'

const ActivityLogs = () => {
  const {
    getActivityLogs,
  } = activityLogServices()
  const [searchFilter, setSearchFilter] = useState<ActivityLogsParam>()
  const [activityLogs, setActivityLogs] = useState<ActivityLogsResponse>()

  const columns: ColumnT[] = [
    {
      label: 'Date Time',
      key: 'created_at',
      width: 'md:w-[15%] 2xl:w-[10%]',
      render: (row) => <> {formatDateTime(row.created_at)}</>,
    },
    {
      label: 'Name',
      key: 'employee_name',
      width: 'md:w-[15%] 2xl:w-[10%]',
    },
    {
      label: 'Role',
      key: 'employee_role',
      width: 'md:w-[10%] 2xl:w-[10%]',
    },
    {
      label: 'Activity Detail',
      key: 'description',
      width: 'md:w-[40%] 2xl:w-[10%]',
    },
  ]

  const getActivityLogsApi = async () => {
    const res = await getActivityLogs(searchFilter)
    setActivityLogs(res)
  }

  useEffect(() => {
    getActivityLogsApi()
  }, [searchFilter])

  return (
    <div className="w-full bg-white flex flex-col">
      <h1 className="font-bold">Activity Logs</h1>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 items-center">
        <SearchFilter
          onChange={(filter) => setSearchFilter({ ...filter, page: 1 })}
          searchFilter={searchFilter}
        />
      </div>
      <div className="overflow-auto">
        <Table
          columns={columns}
          data={activityLogs?.activity_logs ?? []}
        />
      </div>
      <div className="grid justify-end pt-4">
        <Pagination
          total_pages={activityLogs?.meta.total_pages}
          per_page={activityLogs?.meta.per}
          page={activityLogs?.meta.page}
          onPageChange={(page) => setSearchFilter({ ...searchFilter, page: page })}
          onPerPageChange={(value) =>
            setSearchFilter({ ...searchFilter, per: value, page: 1 })
          }
        />
      </div>
    </div>
  )
}

export default withApp(ActivityLogs)
