import { Select } from '@components'
import { LEAVE_STATUS_OPTIONS, LEAVE_TYPE_OPTIONS } from '@lib/constants'
import React from 'react'

type Props = {
  totals: number
}
export const SearchFilter = ({ totals }: Props) => {
  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-[220px_220px_220px_auto] w-full gap-2 items-center">
      <Select options={LEAVE_TYPE_OPTIONS} />
      <Select options={[]} />
      <Select options={LEAVE_STATUS_OPTIONS} />
      <div className="font-bold text-sm">
        Matching: <span className="font-normal">{totals} items</span>
      </div>
    </div>
  )
}
