import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { capitalize } from 'lodash'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { z } from 'zod'

import { formatDateWithPeriod } from '@lib/helper'
import { ApproveTeamLeaveParam, TeamLeavePendingDataT } from '@types'

import { closeModal, ErrorText, Modal, Radio, TextAreaField } from '@components'

export const LEAVE_APPROVE_FORM = 'leave-approve-form'

type Props = {
  data: TeamLeavePendingDataT
  onSubmit: (data: ApproveTeamLeaveParam) => void
}

export const ApproveModal = ({ data, onSubmit }: Props) => {
  const boxData = [
    {
      label: 'Employee Name',
      value: capitalize(data.employee_name),
    },
    {
      label: 'Leave Type',
      value: `${capitalize(data.leave_type_name.split('_').join(' '))} Leave`,
    },
    {
      label: 'Leave Date',
      value: formatDateWithPeriod(data.datetime_range, data.period),
    },
    {
      label: 'Reason',
      value: data?.reason ?? '-',
    },
  ]

  const approveOptions = [
    {
      label: 'Approve',
      value: 'approve',
    },
    {
      label: 'Reject',
      value: 'reject',
    },
  ]

  const schema = z
    .object({
      id: z.number(),
      status: z.string(),
      reason: z.string().optional(),
    })
    .refine(
      (input) => {
        if (input.status === 'approve') return true
        if (input.status === 'reject' && input.reason) return true
        return false
      },
      { message: 'reason is required', path: ['reason'] },
    )

  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    register,
    formState: { errors },
  } = useForm<ApproveTeamLeaveParam>({
    resolver: zodResolver(schema),
    defaultValues: {
      id: 1,
      reason: undefined,
    },
  })

  const watchFields = watch(['status'])

  const isShownReason = useMemo(() => {
    return watchFields[0] === 'reject'
  }, [watchFields])

  return (
    <Modal name={LEAVE_APPROVE_FORM}>
      <div className="grid text-center justify-center w-full">
        <div className="grid gap-5 mb-5 ">
          <img src="/assets/warning_icon.svg" className="m-auto" />
          <h2 className="font-bold">Approve Leave</h2>
        </div>

        <div className="grid gap-4">
          <p className="text-text-subdued">
            Your employee has submitted leave request as below.
          </p>
          <div className="card card-border">
            {boxData.map((item) => (
              <div className="grid grid-cols-[1fr_2fr] text-left text-sm">
                <p className="font-semibold">{item.label}:</p>
                <p className="capitalize">{item.value}</p>
              </div>
            ))}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid justify-start p-[24px_0px] w-full text-start">
            <p>
              Approval <span className="required-icon">*</span>
            </p>
            <div className="grid grid-cols-2 gap-2 w-[455px]">
              {approveOptions.map((item) => (
                <Radio
                  option={item}
                  onChange={(value) => {
                    setValue('status', value as string)
                  }}
                  value={getValues('status')}
                />
              ))}
            </div>
            {errors.status?.message && (
              <ErrorText text={errors.status.message} />
            )}
            {isShownReason && (
              <div className="mt-[24px]">
                <TextAreaField
                  placeholder="Enter reject reason"
                  label="Reject Reason"
                  labelSize="md"
                  name="reason"
                  register={register}
                  required
                  errorMessage={errors.reason?.message}
                />
              </div>
            )}
          </div>
          <div className="flex flex-row gap-4 justify-end pt-6">
            <button
              type="button"
              className="btn btn-primary-outline capitalize"
              onClick={() => closeModal([LEAVE_APPROVE_FORM])}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary-solid">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
