import React, { useMemo } from 'react'
import { MdLogout } from 'react-icons/md'
import queryString from 'query-string'

import { MeResponsesT, MenuT } from '@types'
import { leavesPath, teamLeavesPath, userManagementPath, companySettingsPath, activityLogsPath } from '@path'

import {
  adminPermission,
  managerPermission,
  ownerPermission,
  userPermission,
} from '../constants/permission'
import { authServices } from '../services'
import { HomeIcon, MyLeaveIcon, TeamLeaveIcon, UserIcon, SettingsIcon, LogsIcon } from '@components'

type Props = {
  data: MeResponsesT
}
export const SideBar = ({ data }: Props) => {
  const { logout } = authServices()
  const path = window.location.pathname

  const permission = useMemo(() => {
    switch (data.role) {
      case 'admin':
        return adminPermission
      case 'manager':
        return managerPermission
      case 'owner':
        return ownerPermission
      default:
        return userPermission
    }
  }, [data])

  const Menus: MenuT[] = [
    {
      title: 'Home',
      key: 'home',
      icon: <HomeIcon />,
      path: '/',
    },
    {
      title: 'My Requests',
      key: 'my_leaves',
      icon: <MyLeaveIcon />,
      path: leavesPath,
    },
    {
      title: 'Team Leaves (' + data.pending_leaves_count + ')',
      key: 'team_leaves',
      icon: <TeamLeaveIcon />,
      path: teamLeavesPath,
      query: { page: 'pending' },
    },
    {
      title: 'User Management',
      key: 'user_management',
      icon: <UserIcon />,
      path: userManagementPath,
      query: { page: 'users' },
    },
    {
      title: 'Activity Logs',
      key: 'activity_logs',
      icon: <LogsIcon />,
      path: activityLogsPath,
    },
    {
      title: 'Company Settings',
      key: 'company_settings',
      icon: <SettingsIcon />,
      path: companySettingsPath,
    },
  ]

  return (
    <div className="drawer-side">
      <label htmlFor="my-drawer" className="drawer-overlay" />
      <div className="flex-col w-[250px] h-screen relative">
        <img
          className="sm:w-[230px] md:w-[300px] md:h-[100px] py-[12px] px-[10px]"
          src="/assets/datawow_logo.svg"
          alt="logo"
        />
        <div className="flex w-full p-[4px_28px_20px_22px] gap-4 border-b-[2px] border-border-default-subdued">
          <div className="avatar">
            <div className="w-[44px] h-[44px] rounded-full">
              <img src={data.avatar_url} />
            </div>
          </div>
          <div className="grid">
            <div className="font-semibold">{data.name}</div>
            <div className="text-sm text-text-subdued">{data.role}</div>
          </div>
        </div>
        <div>
          {Menus.filter((item) => permission.includes(item.key)).map(
            (menu, index) => {
              const isActive = menu.path === path || (path.includes(menu.path) && (menu.path != '/'))
              const query = menu.query
                ? queryString.stringify(menu.query)
                : null
              return (
                <a
                  href={`${menu.path}${query ? `?${query}` : ''}`}
                  className={`grid grid-cols-[20px_1fr] w-full p-[16px_30px] gap-[20px] cursor-pointer
             ${
               isActive
                 ? 'bg-surface-selected-default border-r-[3px] border-icon-primary font-bold text-primary-default'
                 : 'bg-white border-r-[2px] border-border-default-subdued sm:border-r-0 md:border-r-[2px]'
             }
             hover:bg-surface-selected-default
              hover:font-bold
              hover:text-primary-600`}
                  key={index}
                >
                  <div
                    className={`${
                      isActive ? 'fill-icon-primary' : 'fill-icon-subdued'
                    }`}
                  >
                    {menu.icon}
                  </div>

                  <p
                    className={`text-sm ${
                      isActive
                        ? 'text-text-primary-default font-bold '
                        : 'text-text-default'
                    }`}
                  >
                    {menu.title}
                  </p>
                </a>
              )
            },
          )}
        </div>
        <div
          className="grid grid-cols-[20px_1fr] w-full p-[20px_30px] gap-[20px] text-sm cursor-pointer absolute bottom-0 border-r-[2px] border-t-[2px] border-border-default-subdued
           hover:bg-surface-selected-default
            hover:font-bold
            hover:text-primary-600`"
          onClick={() => logout()}
        >
          <MdLogout size={20} className="fill-icon-subdued" />
          Logout
        </div>
      </div>
    </div>
  )
}
