import React from 'react'

import { BreadCrumbsT } from '@types'

type Props = {
  data: BreadCrumbsT[]
  current?: string
}

export const BreadCrumb = ({ data, current }: Props) => {
  return (
    <div className="text-sm breadcrumbs">
      <ul>
        {data.map((item) => {
          const isActive = item.key === current
          return (
            <li
              className={`text-sm ${
                isActive
                  ? 'text-text-primary-default font-bold'
                  : 'text-text-default'
              }`}
            >
              {item.href ? <a href={item.href}>{item.title}</a> : item.title}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
