import React, { useMemo, useState } from 'react'

import { OptionT } from '@types'
import { PER_PAGE_OPTIONS } from './constants'
import { Select } from '../Select'

type Props = {
  onPageChange?: (page: number) => void
  onPerPageChange?: (value: number) => void
  total_pages?: number
  per_page?: number
  per_page_options?: OptionT[]
  page?: number
}

export const Pagination = ({
  onPageChange,
  onPerPageChange,
  total_pages = 1,
  per_page = 25,
  per_page_options = PER_PAGE_OPTIONS,
  page = 1,
}: Props) => {
  const [perPage, setPerPage] = useState<number>(per_page)
  const handlePageChange = (value: number) => {
    onPageChange?.(value)
  }

  const handlePerPageChange = (value: number) => {
    setPerPage(value)
    onPerPageChange?.(value)
  }

  const totalPage = useMemo(
    () => (total_pages ? total_pages : 1),
    [total_pages],
  )

  return (
    <div className="flex flex-row items-center gap-4 sm:text-sm md:text-md">
      <div className="sm:hidden md:flex flex-row items-center gap-2">
        <p>Display:</p>
        <div className="dropdown dropdown-bottom">
          <Select
            options={per_page_options}
            value={perPage}
            position="top"
            onChange={(value) => handlePerPageChange(value?.value as number)}
          />
        </div>
        <p className="sm:hidden md:block">{`Showing ${page} – ${totalPage} of ${totalPage} results`}</p>
      </div>
      <p className="sm:block md:hidden ">{`${page} – ${totalPage} of ${totalPage}`}</p>
      <div className="flex flex-row items-center gap-2">
        <div className="btn-group flex flex-row">
          <button
            className="btn bg-white text-icon-default hover:bg-surface-default-hovered border-0"
            disabled={page === 1}
            onClick={() => handlePageChange(page - 1)}
          >
            <svg
              className="w-5 h-5 fill-current"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 16C11.744 16 11.488 15.902 11.293 15.707L6.29301 10.707C5.90201 10.316 5.90201 9.68401 6.29301 9.29301L11.293 4.29301C11.684 3.90201 12.316 3.90201 12.707 4.29301C13.098 4.68401 13.098 5.31601 12.707 5.70701L8.41401 10L12.707 14.293C13.098 14.684 13.098 15.316 12.707 15.707C12.512 15.902 12.256 16 12 16Z"
              />
            </svg>
          </button>
          <button
            className="btn bg-white text-icon-default hover:bg-surface-default-hovered border-0"
            disabled={page >= total_pages}
            onClick={() => handlePageChange(page + 1)}
          >
            <svg
              className="w-5 h-5 fill-current"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00001 16C7.74401 16 7.48801 15.902 7.29301 15.707C6.90201 15.316 6.90201 14.684 7.29301 14.293L11.586 10L7.29301 5.70701C6.90201 5.31601 6.90201 4.68401 7.29301 4.29301C7.68401 3.90201 8.31601 3.90201 8.70701 4.29301L13.707 9.29301C14.098 9.68401 14.098 10.316 13.707 10.707L8.70701 15.707C8.51201 15.902 8.25601 16 8.00001 16Z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}
