import React from 'react'

import { OptionT, EmployeeParam } from '@types'

import { Select } from '@components'

type Props = {
  onChange: (filter: EmployeeParam) => void
  totals: number
  search?: EmployeeParam
  teamOptions: OptionT[]
}
export const SearchFilter = ({
  onChange,
  totals,
  search,
  teamOptions,
}: Props) => {
  return (
    <div className="grid w-full sm:grid-cols-1 md:grid-cols-[180px_auto] xl:grid-cols-[220px_auto] gap-[14px] my-4 md:items-center sm:items-baseline">
      <Select
        onChange={(value) => {
          onChange({ ...search, department_id: value?.value as number })
        }}
        options={teamOptions ?? []}
        value={search?.department_id}
      />

      <div className="font-bold text-sm sm:gap-4">
        Matching: <span className="font-normal">{totals} items</span>
      </div>
    </div>
  )
}
