import React from 'react'
import queryString from 'query-string'

import { TabsT } from '@types'

type Props = {
  tabs: TabsT[]
  currentTab?: string
  onChange?: (key: string) => void
}
export const Tabs = ({ tabs, currentTab, onChange }: Props) => {
  return (
    <div className="tabs h-[60px] border-b-2 border-border-default-subdued">
      {tabs.map((tab, index) => {
        const isActive = tab.key === currentTab
        const query = tab.query ? queryString.stringify(tab.query) : null
        return (
          <a
            href={`${tab.href}${query ? `?${query}` : ''}`}
            className={`tab relative h-[60px] p-[20px_16px] hover:text-text-default sm:text-xs md:text-sm ${
              isActive
                ? 'text-text-default border-action-primary-default'
                : 'text-text-subdued'
            }`}
            onClick={() => onChange?.(tab.key)}
            key={index}
          >
            {tab.title}
            {isActive && (
              <div className="absolute bottom-0 h-[3px] w-full bg-action-primary-default hover:bg-action-primary-hovered rounded-tl-[3px] rounded-tr-[3px]" />
            )}
          </a>
        )
      })}
    </div>
  )
}
