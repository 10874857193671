import { axios } from '@lib/helper'

import { holidayPath } from '@path'
import { HolidayResponse } from '@types'

export const HolidayServices = () => {
  return {
    getHoliday: async (): Promise<HolidayResponse> => {
      const res = await axios.get(holidayPath, {})
      if (res.data) {
        const formatData = res.data?.holiday_data.map(
          (datum: { date: string; title: string }) => ({
            ...datum,
            date: new Date(datum['date']),
          }),
        )
        return { holiday_data: formatData }
      } else {
        return { holiday_data: [] }
      }
    },
  }
}
