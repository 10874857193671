import React from 'react'

type Props = {
  status: string
  type: 'leave' | 'employee'
}
export const Status = ({ status, type }: Props) => {
  const getClassName = () => {
    if (type === 'leave') {
      switch (status) {
        case 'approved':
          return 'bg-surface-success-default'
        case 'rejected':
          return 'bg-surface-critical-default'
        case 'canceled':
          return 'bg-surface-disabled-default'
        default:
          return 'bg-surface-warning-default'
      }
    } else if (type === 'employee') {
      switch (status) {
        case 'active':
          return 'bg-surface-success-default'
        case 'inactive':
          return 'bg-surface-disabled-subdued text-disabled'
      }
    }
  }

  return (
    <div
      className={`capitalize py-0.5 px-2 w-fit rounded-[10px] ${getClassName()}`}
    >
      {status}
    </div>
  )
}
